export default class WebSocketEventManager {
  constructor(socket) {
    this.socket = socket;
    this.eventListeners = {};
  }

  addEventListener(eventName, callback) {
    if (!this.eventListeners[eventName]) {
      this.eventListeners[eventName] = [];
      this.socket.on(eventName, (data) => this.notifyListeners(eventName, data));
    }
    this.eventListeners[eventName].push(callback);
  }

  removeEventListener(eventName, callback) {
    if (this.eventListeners[eventName]) {
      const index = this.eventListeners[eventName].indexOf(callback);
      if (index > -1) {
        this.eventListeners[eventName].splice(index, 1);
      }
    }
  }

  notifyListeners(eventName, data) {
    if (this.eventListeners[eventName]) {
      this.eventListeners[eventName].forEach((callback) => callback(data));
    }
  }
}
