import styled, { css } from "styled-components";

export const Container = styled.div`
  background-color: #f00;
  height: 100vh;
  display: flex;
  width: 100%;
  padding: 2em 2em;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ loading }) => (!loading ? "space-between" : "center")};
`;

export const Title = styled.h1`
  font-size: 2.1rem;
  color: #fff;
  margin: 0;
  font-weight: 500;
`;

export const SubTitle = styled.h2`
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  font-weight: 400;
  margin-bottom: 1em;
`;

export const ConfirmButton = styled.button`
  background-color: #9f0e0d;
  border: none;
  border-radius: 12px;
  width: 100%;
  padding: 1.2em 2em;
  font-size: 15px;
  font-weight: 500;
  min-height: 65px;
  color: #ffffff;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
`;

export const Logo = styled.img`
  margin-top: 20em;

  max-width: 200px;
`;

export const ContainerFooter = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;
