import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as S from "./styles";
import ProductList from "../../components/ProductList";
import { useWebSocket } from "../../hooks/useWebSocket";
import useMe from "../../hooks/useMe";
import { useParams } from "react-router-dom";
import { STATUS_TYPE } from "../../config/statusType";
import { formatCurrency } from "../../utils/currency";

const MyOrders = () => {
  const { id } = useParams();
  const me = useMe();
  const { joinRoom, addEventListener, removeEventListener, emitEvent } =
    useWebSocket();

  const [data, setData] = useState({});

  useEffect(() => {
    if (me?.id) {
      joinRoom(me?.id);
    }
  }, [me?.id, joinRoom]);

  useEffect(() => {
    const handleMessages = (message) => {
      console.log("message", message);
      setData(message);
    };

    addEventListener("ORDER_DETAIL", handleMessages);
    emitEvent("GET_ORDER_DETAIL", { id, userId: me?.id });

    return () => removeEventListener("ORDER_DETAIL", handleMessages);
  }, [me]);

  const listProducts = data?.items?.map((item) => ({
    ...item.product,
  }));

  useEffect(() => {
    Swal.fire({
      title: "Buscando pedido...",
      text: `Pedido #${id}`,
      icon: "info",
      confirmButtonText: "Ok",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      beforeOpen: () => {
        Swal.isLoading();
        Swal.showLoading();
      },
    });

    if (data?.id) {
      Swal.close();
    }
  }, [data]);

  if (!data?.id) {
    return null;
  }

  return (
    <S.Container>
      <S.Header>
        <S.Title>Código de retirada #{data?.code}</S.Title>

        <S.Items>
          <S.Item>
            <S.Label>Status: </S.Label>{" "}
            <S.Value>{STATUS_TYPE[data?.status] || ""}</S.Value>
          </S.Item>

          <S.Item>
            <S.Label>Nome do restaurante: </S.Label>{" "}
            <S.Value>{data?.restaurant?.title}</S.Value>
          </S.Item>
          <S.Item>
            <S.Label>Valor total: </S.Label>{" "}
            <S.Value>{formatCurrency(data?.value)}</S.Value>
          </S.Item>
        </S.Items>
      </S.Header>

      <S.Header>
        <S.Title>Produtos</S.Title>
        <ProductList
          onProductClick={() => {}}
          products={listProducts}
          showPrice={false}
        />
      </S.Header>
    </S.Container>
  );
};

export default MyOrders;
