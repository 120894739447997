import * as S from './styles';

const Footer = () => {
  return (
    <S.Container>
      <S.FooterLinks>
        <S.FooterLink href='/terms-conditions'>
          Termos de Uso e Condições
        </S.FooterLink>
        <S.Copyright>All Rights Reserved ® E-pedido</S.Copyright>
      </S.FooterLinks>
    </S.Container>
  );
};

export default Footer;
