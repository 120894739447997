import React from 'react';
import * as S from './styles';

const GreetingSection = ({ userName }) => (
  <S.GreetingContainer>
    <S.WelcomeMessage>Olá {userName} 👋🏻</S.WelcomeMessage>
    <S.SubMessage>Seja bem vindo ao e-pedido.</S.SubMessage>
  </S.GreetingContainer>
);

export default GreetingSection;
