// RatingScreenPage.js or another suitable name
import React from 'react';
import RatingScreen from '../../components/RatingScreen'; // Adjust the import path as needed
import * as S from './styles'; // Adjust the path as needed

const RatingScreenData = () => {
  return (
    <S.Container>
      <RatingScreen />
    </S.Container>
  );
};

export default RatingScreenData;
