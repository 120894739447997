import styled from "styled-components";

export const Logo = styled.img`
  width: 30%;
  z-index: 1;
  height: auto;
  margin: 0;
`;

export const Branding = styled.img`
  width: 40%;
  z-index: 1;
  height: auto;
  margin: 0 auto;
`;

export const Space = styled.div`
  width: 100%;
  margin-bottom: 7em;
`;
export const Header = styled.div`
  display: flex;
  padding: 3em 2em;

  align-items: center;
  justify-content: center;

  transition: top 0.3s;
  z-index: 100;

  position: fixed;
  top: ${(props) =>
    props.isVisible ? "0" : "-100px"}; // Hide or show based on scroll
`;

export const WelcomeText = styled.p`
  background: #ffffff;
  color: #4f4f4f;

  text-align: center;

  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #eaeaea;
`;
