import ChatContent from '../../components/ChatContent';

const Chat = () => {
  return (
    <>
      <ChatContent />
    </>
  );
};

export default Chat;
