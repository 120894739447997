import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  // Import Poppins font from Google Fonts
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    // Set Poppins as the default font family
    font-family: 'Poppins', sans-serif;
  }
  
  html {
    font-size: 62.5%; // This sets 1 rem to equal 10px
    height: 100vh;
    margin: auto;
    max-width: 480px;
  }
  
  body, html {
    font-family: 'Poppins', sans-serif; // Ensure Poppins is used here as well
    background-color: #f9f9f9;  
  }
    
  body {
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
  }

  .-no-overflow {
    overflow: hidden;
  }
`;

export const Main = styled.main`
  max-width: 480px;
  position: relative;
`