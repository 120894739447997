import UserAccountMenu from '../../../components/UserAccountMenu';
import * as S from './styles';

const Menu = () => {
  return (
    <S.Container>
      <UserAccountMenu />
    </S.Container>
  );
};

export default Menu;
