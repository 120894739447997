import { useEffect, useState } from 'react';
import * as S from './styles';
import { httpServiceCms } from '../../config/httpService';


const TextDisplay = ({ data }) => {
  return (
    <div>
      {data.attributes.description.map((paragraph, index) => (
        <p key={index}>
          {paragraph.children.map((child, childIndex) => (
            <span key={childIndex}>{child.text}</span>
          ))}
        </p>
      ))}
    </div>
  );
};

const Terms = () => {
  const [data, setData] = useState({})

  const getTerms = async () => {
    const { data: response } = await httpServiceCms.get('/term');

    setData(response.data);
  }

  useEffect(() => {
    getTerms()
  }, [])
  
  return data.id && (
    <S.Container>
       <TextDisplay data={data} />
    </S.Container>
  );
};

export default Terms;
