import { useState } from 'react';
import Swal from 'sweetalert2';
import * as servicesProducts from '../../services/products'

export const useProducts = () => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [sideDishes, setSideDishes] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadings, setLoadings] = useState({
    getProducts: false,
    getSideDishes: false,
    getProductById: false,
  });

  const getProducts = async (restaurant) => {
    try {
      setLoading(true)
      const products = await servicesProducts.getProducts(restaurant);
      setProducts(products);
    } catch (error) {
      setProducts([]);
      console.error(error);
      Swal.fire({
        title: 'Erro ao buscar os produtos',
        text: 'Ocorreu um erro ao buscar os produtos, por favor tente novamente',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    } finally {
      setLoading(false);
    }
  };

  const getSideDishes = async (product) => {
    try {
      setLoadings({ ...loadings, getSideDishes: true });
      const sideDishes = await servicesProducts.getSideDishes(product);
      setSideDishes(sideDishes);
    } catch (error) {
      setSideDishes([]);
      console.error(error);
      Swal.fire({
        title: 'Erro ao buscar os acompanhamentos',
        text: 'Ocorreu um erro ao buscar os acompanhamentos, por favor tente novamente',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    } finally {
      setLoadings({ ...loadings, getSideDishes: false });
    }
  };

  const getProductById = async (id) => {
    try {
      setLoadings({ ...loadings, getProductById: true });
      const product = await servicesProducts.getProductById(id);
      setProduct(product);
    } catch (error) {
      setProduct({});
      console.error(error);
      Swal.fire({
        title: 'Erro ao buscar o produto',
        text: 'Ocorreu um erro ao buscar o produto, por favor tente novamente',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    } finally {
      setLoadings({ ...loadings, getProductById: false });
    }
  };

  return { products, product, sideDishes, loading, loadings, getProducts, getSideDishes, getProductById };
}