import axios from 'axios';
import { API_URL_MIDDLEWARE, TOKEN_NAME } from '../env';

console.log('API_URL_MIDDLEWARE:::', API_URL_MIDDLEWARE);

export const httpService = axios.create({
  baseURL: API_URL_MIDDLEWARE,
});

// create interceptor to add token to requests
httpService.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN_NAME);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

// // create interceptor for 401 responses
httpService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem(TOKEN_NAME);
      return {
        unauthorized: true,
      }
    }

    throw error;
  },
);
