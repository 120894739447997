import styled from 'styled-components';

export const Container = styled.div``;

export const HeadSection = styled.div`
  background-color: #fa0c0c;
  padding: 2em 2em 3em;
  z-index: 1;

  border-radius: 0 0 31px 31px;
`;
