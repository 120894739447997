import { parseResponse } from "../../utils";
import { httpService } from "../axios"

export const getHangars = async (coords) => {
  const { data } = await httpService.get("/hangars", {
    params: {
      latitude: coords.latitude,
      longitude: coords.longitude,
    },
  });

  return parseResponse(data.hangars);
}