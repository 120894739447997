import React from "react";
import CategorySlider from "../../components/CategorySlider";
import Highbanners from "../../components/Highbanners";
import HighlightsSection from "../../components/HighlightsSection";

const Discounts = () => {
  return (
    <>
      <Highbanners />
      {/* <HighlightsSection /> */}
      <CategorySlider />
    </>
  );
};

export default Discounts;
