import React, { useEffect } from "react";
import GreetingSection from "../../components/GreetingSection";
import LocationSelector from "../../components/LocationSelector";
// import MenuIcons from "../../components/MenuIcons";
import RestaurantList from "../../components/RestaurantList";
import * as S from "./styles";
import { getLocation } from "../../utils/getLocation";
import { useHangarContext } from "../../context/HangarProvider";
import { useNavigate } from "react-router-dom";
import { useRestaurants } from "../../hooks/useRestaurants";
import useMe from "../../hooks/useMe";
import { useHangars } from "../../hooks/useHangars";
import Loading from "../../components/Loading";

const HomePage = () => {
  const { hangar, selectHangar } = useHangarContext();
  const { getHangars, hangars, loading: loadingHangars } = useHangars();
  const {
    getRestaurants,
    restaurants,
    loading: loadingRestaurants,
  } = useRestaurants();
  const info = useMe();

  const navigate = useNavigate();

  useEffect(() => {
    if (!hangar?.id) {
      navigate("/selecionar-shopping");
    }

    if (hangar?.id) {
      getRestaurants(hangar);
    }
  }, [hangar]);

  useEffect(() => {
    getLocation().then((coords) => {
      getHangars(coords);
    });
  }, []);

  return (
    <>
      <S.HeadSection>
        <GreetingSection userName={info?.name} />
        {!loadingHangars && (
          <LocationSelector
            selectedHangar={hangar}
            hangars={hangars}
            selectValue={selectHangar}
          />
        )}
        {/* <MenuIcons /> */}
      </S.HeadSection>
      {/* <CategorySlider /> */}
      {/* <HighlightsSection /> */}
      {hangar?.id && (
        <>
          <RestaurantList restaurants={restaurants} />

          {loadingRestaurants && (
            <S.Wrap>
              <Loading message="Carregando restaurantes..." />
            </S.Wrap>
          )}
        </>
      )}
    </>
  );
};

export default HomePage;
