// index.js
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { LuListChecks } from "react-icons/lu";
import { TbMapPinCheck } from "react-icons/tb";
import * as S from "./styles"; // Import all styled components as S
import useMe from "../../hooks/useMe";
import Swal from "sweetalert2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";

let timerInterval;
const OrderReadyScreen = ({ orderCode, orderId }) => {
  const navigate = useNavigate();
  const ref = useRef();
  const [isOrderReady, setIsOrderReady] = useState(false);
  const me = useMe();
  useEffect(() => {
    if (orderId) {
      setTimeout(() => {
        Swal.fire({
          title: "Tudo certo!",
          html: "Você será redirecionado em <b></b> segundos",
          icon: "success",
          confirmButtonText: "Entendi",
          timer: 5000,
          timerProgressBar: true,
          showCancelButton: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            timerInterval = setInterval(() => {
              timer.textContent = `${parseInt(Swal.getTimerLeft() / 1000) + 1}`;
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
            navigate(`/minha-conta/meus-pedidos/${orderId}`);
          },
        }).then(() => {}, []);
      }, 8000);
    }
  }, [orderId]);

  const handleCopied = useCallback(() => {
    Swal.fire({
      title: "Código copiado!",
      text: "Apresente o código no restaurante para retirar seu pedido.",
      icon: "success",
      confirmButtonText: "Entendi",
    }).then(() => {
      navigate(`/minha-conta/meus-pedidos/${orderId}`);
    });
  }, [orderId]);

  return (
    <S.ScreenContainer isReady={isOrderReady}>
      <S.StatusIcon>
        {isOrderReady ? <LuListChecks /> : <TbMapPinCheck />}
      </S.StatusIcon>
      <S.Title>
        {isOrderReady
          ? "Seu pedido está pronto, pode retirar!"
          : "Pedido realizado com sucesso."}
      </S.Title>
      <S.Subtitle>
        {isOrderReady
          ? "Apresente o código abaixo e retire"
          : `Olá ${me?.name}, seu pedido foi enviado com sucesso, aguarde o restaurante confirmar. Abaixo está o código do seu pedido.`}
      </S.Subtitle>
      <CopyToClipboard ref={ref} text={orderCode} onCopy={handleCopied}>
        <S.OrderCode>{orderCode}</S.OrderCode>
      </CopyToClipboard>
      <S.Subtitle>Clique acima para copiar e voltar ao início</S.Subtitle>
    </S.ScreenContainer>
  );
};

export default OrderReadyScreen;
