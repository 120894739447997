import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";
import { TOKEN_NAME } from "../../env";
import { BiCart, BiLogOut } from "react-icons/bi";
import { RiProfileFill } from "react-icons/ri";
import { useOrders } from "../../hooks/useOrders";
import Swal from "sweetalert2";
import { STATUS_TYPE } from "../../config/statusType";
import { formatCurrency } from "../../utils/currency";
import { formatDate } from "../../utils";

const MyOrdersList = () => {
  const navigate = useNavigate();
  const { orders, getOrders, loading } = useOrders();

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_NAME);

    if (!token) {
      navigate("/minha-conta");
    }
  }, []);

  const navigateToMyOrders = (id) => {
    navigate(`/minha-conta/meus-pedidos/${id}`);
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    Swal.fire({
      title: "Buscando pedidos...",
      icon: "info",
      confirmButtonText: "Ok",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      beforeOpen: () => {
        Swal.isLoading();
        Swal.showLoading();
      },
    });

    if (!loading) {
      Swal.close();
    }
  }, [loading]);

  console.log("orders", orders);

  return (
    <>
      <S.AccountMenuContainer>
        {orders?.map((order) => (
          <S.MenuItem
            key={order.id}
            onClick={() => navigateToMyOrders(order.id)}
          >
            <div>
              <S.MenuText>
                #{order.code}
                <br />
              </S.MenuText>

              <S.MenuText>
                {order?.restaurant?.title} | {STATUS_TYPE[order.status]} |{" "}
                {formatCurrency(order?.value)} <br />
              </S.MenuText>

              <S.MenuText>
                Pedido feito em: {formatDate(order?.dateTime)}
              </S.MenuText>
            </div>
          </S.MenuItem>
        ))}
      </S.AccountMenuContainer>
    </>
  );
};

export default MyOrdersList;
