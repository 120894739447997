import styled, { keyframes } from 'styled-components';
export const CurrentLocationButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MapButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    background-color: #45a049;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const ErrorMessage = styled.div`
  position: absolute;
  top: -25px;
  left: 0;
  color: red;
  font-size: 0.8rem;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 2s linear infinite;
`;

export const DropdownSelect = styled.select`
  width: 100%;
  padding: 1em 2em;
  border-radius: 15px;
  height: 65px;
  border: 1px solid #ccc;
  appearance: none; // Remove o estilo padrão do navegador
  background-color: white;
  font-size: 16px;
  cursor: pointer;
  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    pointer-events: none; // Para clicar através do ícone
  }
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const DropdownIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; // Para clicar através do ícone
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 20px;
  position: relative;
`;

export const SearchInput = styled.input`
  flex-grow: 1;
  border: 1px solid #ccc;
  background-color: white;
  padding: 10px;
  padding-left: 40px; // Space for icon
  font-size: 1rem;
  min-height: 65px;
  border-radius: 15px;
  &:focus {
    outline: none;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Dropdown = styled.div`
  position: relative;
  width: 100%;

  margin-top: 2em;

  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  svg {
    font-size: 18px;
  }
`;

export const DropdownHeader = styled.div`
  padding: 1em 1.5em;
  margin: 0 1em;
  font-size: 16px;
  border-radius: 15px;
  height: 65px;
  width: 100%;
  border: none;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 7px 30px 0px #acacac42;

  svg {
    pointer-events: none;
    font-size: 20px;
  }
`;

export const DropdownList = styled.div`
  width: 100%;
  border-top: none;
  margin: 1em auto;
  max-height: 150px;
  background-color: white;
  padding: 0px;
  overflow-y: auto;
  border-radius: 15px;
  box-shadow: 0px 7px 30px 0px #acacac42;
`;

export const DropdownItem = styled.div`
  padding: 2em 1.5em;
  min-height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  border-radius: 15px;
  gap: 10px;

  svg {
    font-size: 24px;
  }
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const DistanceNotification = styled.div`
  padding: 6px 9px;
  background-color: #e9ecef;
  border-radius: 10px;
  font-size: 12px;
  color: #495057;
`;
export const InputIcon = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
`;
