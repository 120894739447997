import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
export const Title = styled.div`
  color: var(--Ligth-Grey, #595959);

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 1em;
`;

export const SupportImage = styled.img`
  width: 80%;
  margin: 2em auto 0;
`;

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: 8px;
  padding: 2em 2em;
  margin: auto;
`;

export const SupportButton = styled.button`
  border-radius: 15px;
  background: var(--Green, #fa0c0c);
  padding: 2.5em 2em;
  margin: 5px 0;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: all 0.3s ease;
  font-size: 16px;
  border: 1px solid #fa0c0c;
  &:hover {
    opacity: 0.9;
  }
`;

export const SupportEmailButton = styled.button`
  border-radius: 15px;
  background: var(--Green, #fff);
  padding: 2.5em 2em;
  font-size: 16px;
  margin: 5px 0;
  border: none;
  color: #595959;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    font-size: 28px;
  }

  transition: all 0.3s ease;
  &:hover {
    opacity: 0.9;
    border: 1px solid #fa0c0c;
    color: #fa0c0c;
  }
`;

export const SupportTitle = styled.h2`
  margin-bottom: 20px;
`;

export const IconButton = styled.img`
  width: 24px;
  height: 24px;
`;
