import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 0;
`

export const Header = styled.div`
  padding: 2em;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`

export const Title = styled.h1`
  font-size: 25px;
  padding: 0 1em;
  margin-bottom: 1em;
  line-height: 32px;
  font-weight: 500;
  text-align: center;
`

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`

export const Item = styled.div`
  display: flex;
  gap: 1em;
`

export const Label = styled.p`
  font-weight: 700;
  font-size: 15px;
`
const typeStatus = {
  'Rejeitado': '#f00',
  'Pendente': '#ff9547',
  'Pago': '#0f0',
  'Preparando': '#00f',
  'Pronto': '#3dff00',
  'Entregue': '#f0f',
}

export const Value = styled.p`
  font-size: 15px;
  color: ${(props) => typeStatus[props.children] || '#000'};
`

export const ValueList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
`

export const ValueListItem = styled.li`
  font-size: 15px;
`