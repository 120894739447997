import React from "react";
import { BiCategoryAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";

const categories = [
  {
    name: "Americana",
    imageUrl: "/assets/categories/americana.png",
    route: "/category",
  },
  {
    name: "Brasileira",
    imageUrl: "/assets/categories/brasileira.png",
    route: "/category",
  },
  {
    name: "Hamburguer",
    imageUrl: "/assets/categories/hamburger.png",
    route: "/category",
  },
  {
    name: "Marmitas",
    imageUrl: "/assets/categories/marmitas.png",
    route: "/category",
  },
  {
    name: "Mexicano",
    imageUrl: "/assets/categories/mexicano.png",
    route: "/category",
  },
  {
    name: "Saudável",
    imageUrl: "/assets/categories/saudavel.png",
    route: "/category",
  },
  {
    name: "Sucos",
    imageUrl: "/assets/categories/sucos.png",
    route: "/category",
  },
];

const CategorySlider = () => {
  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
  };

  return (
    <>
      <S.Title>
        Filtrar por lugar
        <BiCategoryAlt />
      </S.Title>
      <S.SectionContainerComplete>
        <S.SliderContainer>
          {categories.map((category) => (
            <S.CategoryButton
              key={category.name}
              onClick={() => handleClick(category.route)}
            >
              <S.CategoryImage src={category.imageUrl} alt={category.name} />
              <S.CategoryName>{category.name}</S.CategoryName>
            </S.CategoryButton>
          ))}
        </S.SliderContainer>
      </S.SectionContainerComplete>
    </>
  );
};

export default CategorySlider;
