import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f9f9f9;
  padding-bottom: 160px;
  padding-top: 40px;
`;

export const FooterLinks = styled.div`
  text-align: center;
  font-size: 12px;
  color: #666;
`;

export const FooterLink = styled.a`
  margin: 0 5px;
  color: inherit;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Copyright = styled.h3`
  width: 100%;
  margin-top: 0.4em;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #666;
`;
