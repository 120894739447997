import { IoIosAddCircleOutline, IoIosArrowBack } from "react-icons/io";
import * as S from "./styles";
import { Form, useNavigate, useParams } from "react-router-dom";
import {
  BiLock,
  BiMailSend,
  BiSolidUserCircle,
  BiUserCircle,
} from "react-icons/bi";
import { TbLock, TbLockPlus, TbMail, TbMailBolt } from "react-icons/tb";
import { useEffect, useState } from "react";
import { object, string } from "yup";
import { httpService } from "../../services/axios";
import Swal from "sweetalert2";

const logo = "/assets/header/logo-red.svg";

const Schema = object().shape({
  email: string().email("Insira um email válido").required("Insira o email"),
});

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");

  const navigate = useNavigate();

  const goHome = () => {
    navigate("/selecionar-shopping");
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    const validationError = await Schema.validate({
      email,
    })
      .then(() => false)
      .catch((e) => ({
        error: e,
      }));

    if (validationError) {
      setErrors("Insira um email");
      return;
    }
    try {
      setLoading(true);
      Swal.fire({
        title: "Aguarde...",
        text: "Enviando a recuperação no email",
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      const { data: response } = await httpService.post(
        "/auth/password/reset",
        {
          email,
        }
      );

      Swal.fire({
        title: "Sucesso!!",
        icon: "success",
        text: "A recuperação de senha foi enviada",
        showConfirmButton: true,
      });
    } catch (error) {
      Swal.fire({
        title: "erro...",
        text: "Não foi posssivel redefinir a senha.",
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <S.Container>
      <S.Content>
        <S.Logo src={logo} alt="Epedido" />
        <TbLock size={80} color="red" />
        <S.Form onSubmit={handleResetPassword}>
          <S.TitleInput>Insira o email para redefinir sua senha</S.TitleInput>
          <S.InputGroup>
            <TbMail />
            <S.Input
              name="email"
              onChange={handleChange}
              placeholder="E-mail"
              icon="envelope"
            />
          </S.InputGroup>
          {errors && <S.ErrorText>{errors}</S.ErrorText>}

          <S.SendButton disabled={loading}>Enviar</S.SendButton>
        </S.Form>
        <S.BackButton onClick={goHome}>Voltar a o início</S.BackButton>
      </S.Content>
    </S.Container>
  );
};
export default ForgotPassword;
