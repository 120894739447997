import React from "react";
import { useSearchParams } from "react-router-dom";
import AccountToggle from "../../components/AccountToggle";
import * as S from "./styles";

function AccountPage() {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");

  return (
    <S.Container>
      <AccountToggle redirect={redirect} />
    </S.Container>
  );
}

export default AccountPage;
