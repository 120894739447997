// CreditCardForm.js
import React, { useEffect, useState } from "react";
import * as S from "./styles";
import ReactInputMask from "react-input-mask";

const CreditCardForm = ({ onSave, setType, type }) => {
  const [cardData, setCardData] = useState({
    number: "",
    holder_name: "",
    holder_document: "",
    exp_month: "",
    exp_year: "",
    cvv: "",
  });

  const removeFormat = (value) => value.replace(/[^0-9]/g, "");

  const handleSubmit = (event) => {
    event.preventDefault();

    const payload = {
      ...cardData,
      number: removeFormat(cardData.number),
      holder_document: removeFormat(cardData.holder_document),
      exp_month: parseInt(cardData.exp_month),
      exp_year: parseInt(cardData.exp_year),
    };

    onSave(payload, type);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setCardData({
      ...cardData,
      [name]: value,
    });
  };

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.Label htmlFor="number">Tipo de cartão:</S.Label>
      <S.RadioWrap>
        <S.Wrap>
          <input
            checked={type === "credit"}
            maskChar={null}
            onChange={() => setType("credit")}
            name="type"
            value="credit"
            type="radio"
            required
          />
          <S.Label htmlFor="number">Crédito</S.Label>
        </S.Wrap>
        <S.Wrap>
          <input
            checked={type === "voucher"}
            maskChar={null}
            onChange={() => setType("voucher")}
            value="voucher"
            name="type"
            type="radio"
            required
          />
          <S.Label htmlFor="number">VA/VR</S.Label>
        </S.Wrap>
      </S.RadioWrap>
      <S.Label htmlFor="number">Número do Cartão:</S.Label>
      <ReactInputMask
        mask="9999 9999 9999 9999"
        autoComplete="cc-number"
        maskChar={null}
        onChange={handleChange}
        id="number"
        name="number"
        type="text"
        required
      >
        {(inputProps) => <S.Input {...inputProps} />}
      </ReactInputMask>

      <S.Label htmlFor="holder_name">Nome do Titular:</S.Label>
      <S.Input
        id="holder_name"
        name="holder_name"
        type="text"
        autoComplete="cc-name"
        required
        onChange={handleChange}
        pattern="[A-Za-z ]{3,}"
      />

      <S.Label htmlFor="holder_document">CPF do Titular:</S.Label>
      <ReactInputMask
        mask="999.999.999-99"
        autoComplete="cc-document"
        maskChar={null}
        onChange={handleChange}
        id="holder_document"
        name="holder_document"
        type="text"
        required
      >
        {(inputProps) => <S.Input {...inputProps} />}
      </ReactInputMask>

      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "49.5%",
            marginRight: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <S.Label htmlFor="exp_month">Mês de Validade:</S.Label>
          <ReactInputMask
            mask="99"
            maskChar={null}
            onChange={handleChange}
            id="exp_month"
            name="exp_month"
            type="text"
            autoComplete="cc-exp-month"
            required
            pattern="[0-9]{2}"
          >
            {(inputProps) => <S.Input {...inputProps} />}
          </ReactInputMask>
        </div>

        <div
          style={{
            width: "49.5%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <S.Label htmlFor="exp_year">Ano de Validade:</S.Label>
          <ReactInputMask
            mask="99"
            autoComplete="cc-exp-year"
            maskChar={null}
            onChange={handleChange}
            id="exp_year"
            name="exp_year"
            type="text"
            required
            pattern="[0-9]{2}"
          >
            {(inputProps) => <S.Input {...inputProps} />}
          </ReactInputMask>
        </div>
      </div>

      <S.Label htmlFor="cvv">CVV:</S.Label>

      <ReactInputMask
        mask="9999"
        autoComplete="cc-csc"
        pattern="[0-9]{3,4}"
        maskChar={null}
        onChange={handleChange}
        id="cvv"
        name="cvv"
        type="text"
        required
      >
        {(inputProps) => <S.Input {...inputProps} />}
      </ReactInputMask>

      <S.SubmitButton type="submit">Salvar Cartão</S.SubmitButton>
    </S.Form>
  );
};

export default CreditCardForm;
