import PixPageScreen from '../../../components/PixPageScreen';
import * as S from './styles';

const PixPage = () => {
  return (
    <>
      <S.Container>
        <PixPageScreen />
      </S.Container>
    </>
  );
};

export default PixPage;
