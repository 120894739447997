// src/components/MobileMenu/MobileMenu.js
import React from "react";
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";
import { IoRestaurantOutline } from "react-icons/io5";
import { PiUserList } from "react-icons/pi";
import { TbHeartDiscount, TbSmartHome } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import * as S from "./styles";

const MobileMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (pathname, otherPath, withIncludes) => {
    if(withIncludes) {
      return location.pathname.includes(pathname);
    }
    return location.pathname === pathname || location.pathname === otherPath;
  }

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <S.Container>
      <S.Content>
        {/* <S.MenuItem onClick={() => handleNavigation("/discounts")}>
          <S.MenuIcon isActive={isActive("/discounts")}>
            <TbHeartDiscount />
          </S.MenuIcon>
          Descontos
        </S.MenuItem> */}
        <S.MenuItem onClick={() => handleNavigation("/minha-conta")}>
          <S.MenuIcon
            isActive={
              isActive("/minha-conta") ||
              isActive("/minha-conta/menu") ||
              isActive("/minha-conta/configuracoes") || 
              isActive("/minha-conta/meus-pedidos") || 
              isActive("/minha-conta/meus-pedidos",'minha-conta/meus-pedidos/', true)
            }
          >
            <PiUserList />
          </S.MenuIcon>
          Meu Perfil
        </S.MenuItem>
        <S.MenuItem onClick={() => handleNavigation("/inicio")}>
          <S.MenuIcon isActive={isActive("/inicio")}>
            <TbSmartHome />
          </S.MenuIcon>
          Início
        </S.MenuItem>
        <S.MenuItem onClick={() => handleNavigation("/chat")}>
          <S.MenuIcon isActive={isActive("/chat")}>
            <HiOutlineChatBubbleBottomCenterText />
          </S.MenuIcon>
          Suporte
        </S.MenuItem>
      </S.Content>
    </S.Container>
  );
};

export default MobileMenu;
