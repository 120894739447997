import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import LocationSelector from "../../components/LocationSelector";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { useHangars } from "../../hooks/useHangars";
import { useHangarContext } from "../../context/HangarProvider";
import Swal from "sweetalert2";
import { getLocation } from "../../utils/getLocation";

const SelectShopping = () => {
  const navigate = useNavigate();

  const { getHangars, hangars, loading } = useHangars();
  const { hangar, selectHangar } = useHangarContext();
  const [withGeolocation, setWithGeolocation] = useState(false);

  useEffect(() => {
    getLocation().then((coords) => {
      getHangars(coords);
      setWithGeolocation(true);
    });
  }, []);

  const redirectToHome = () => {
    if (!hangar.id) {
      return Swal.fire({
        title: "Selecione um shopping",
        text: "Por favor, selecione um shopping para continuar",
        icon: "error",
        confirmButtonText: "OK",
      });
    }

    navigate("/inicio");
  };

  const logo = "/assets/header/logo.svg";

  return (
    <>
      <Header />
      <S.Container loading={loading}>
        <S.Logo src={logo} alt="Epedido" />
        {loading ? (
          <S.Title>Buscando lista de shoppings...</S.Title>
        ) : (
          <>
            {!withGeolocation && <S.Title>Carregando...</S.Title>}

            {withGeolocation ? (
              <S.ContainerFooter>
                <S.Title>Selecione o shopping</S.Title>
                <S.SubTitle>
                  Escolha o shopping que deseja buscar sua comida
                </S.SubTitle>

                <LocationSelector
                  selectedHangar={hangar}
                  hangars={hangars}
                  selectValue={selectHangar}
                />

                <S.ConfirmButton onClick={redirectToHome}>
                  Confirmar e prosseguir
                </S.ConfirmButton>
              </S.ContainerFooter>
            ) : (
              <>
                <S.Title>Geolocalização não disponível</S.Title>
                <S.SubTitle>
                  Por favor, habilite a geolocalização para continuar
                </S.SubTitle>
              </>
            )}
          </>
        )}
      </S.Container>
    </>
  );
};

export default SelectShopping;
