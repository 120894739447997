import React, { useState } from 'react';
import { MdStar, MdStarBorder } from 'react-icons/md';
import * as S from './styles'; // Adjust the path as needed

const RatingScreen = () => {
  const [rating, setRating] = useState(0);

  const handleStarClick = (index) => {
    setRating(index + 1);
  };

  return (
    <S.ScreenContainer>
      <S.Title>Avalie seu pedido</S.Title>
      <S.Subtitle>
        Toque nas estrelas para avaliar o seu pedido agora
      </S.Subtitle>
      <S.StarsContainer>
        {[...Array(5)].map((_, index) => (
          <S.Star onClick={() => handleStarClick(index)}>
            {index < rating ? <MdStar /> : <MdStarBorder />}
          </S.Star>
        ))}
      </S.StarsContainer>
      <S.RateButton>Avaliar meu pedido</S.RateButton>
      <S.Illustration
        src='/assets/checkout/review.svg'
        alt='Celebration Illustration'
      />
    </S.ScreenContainer>
  );
};

export default RatingScreen;
