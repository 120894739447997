import BlankTemplate from "../templates/Blank";

import Account from "../pages/Account";
import Menu from "../pages/Account/Menu";
import UserSettings from "../pages/Account/UserSettings";
import Categorie from "../pages/Categorie";
import Chat from "../pages/Chat";
import Discounts from "../pages/Discounts";
import Discover from "../pages/Discover";
import HomePage from "../pages/Homepage";
import RatingScreenData from "../pages/RatingScreenData";
import CheckoutScreen from "../pages/Restaurants/CheckoutScreen";
import Restaurant from "../pages/Restaurants/Restaurant";
import FoodPage from "../pages/Restaurants/Restaurant/FoodPage";
import ThankYou from "../pages/Restaurants/ThankYou";
import SelectShopping from "../pages/SelectShopping";
import Terms from "../pages/Terms";
import PixPage from "../pages/Restaurants/PixPage";
import MyOrders from "../pages/MyOrders";
import MyOrdersList from "../pages/Account/MyOrders";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";

export const routes = [
  {
    path: "/restaurante/:slug/:id",
    page: Restaurant,
    title: "Restaurante",
    isPublic: true,
    template: BlankTemplate,
    mobileMenu: true,
    withNavigation: true,
  },
  {
    path: "/restaurante/:resturantSlug/:restaurantId/:productSlug/:productId",
    page: FoodPage,
    title: "Produto",
    isPublic: true,
    template: BlankTemplate,
    withNavigation: true,
  },

  {
    path: "/checkout",
    page: CheckoutScreen,
    title: "Checkout",
    isPublic: true,
    template: BlankTemplate,
    footer: true,
    withNavigation: true,
  },

  {
    path: "/checkout/sucesso/:id",
    page: ThankYou,
    title: "Obrigado por comprar",
    isPublic: true,
    template: BlankTemplate,
    withNavigation: true,
    footer: true,
  },

  {
    path: "/checkout/pix/:id",
    page: PixPage,
    title: "Aguardando pagamento",
    isPublic: true,
    template: BlankTemplate,
    withNavigation: true,
    footer: true,
  },

  // ACCOUNT FLUX
  {
    path: "/minha-conta",
    page: Account,
    title: "Conta",
    isPublic: true,
    template: BlankTemplate,
    mobileMenu: true,
  },

  {
    path: "/esqueci-minha-senha",
    page: ForgotPassword,
    title: "Conta",
    isPublic: true,
    template: BlankTemplate,
  },
  {
    path: "/redefinir-minha-senha",
    page: ResetPassword,
    title: "Conta",
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: "/minha-conta/menu",
    page: Menu,
    title: "Menu",
    isPublic: false,
    template: BlankTemplate,
    withNavigation: true,
    mobileMenu: true,
  },

  {
    path: "/minha-conta/meus-pedidos",
    page: MyOrdersList,
    title: "Meus Pedidos",
    isPublic: false,
    template: BlankTemplate,
    withNavigation: true,
    mobileMenu: true,
  },

  {
    path: "/minha-conta/configuracoes",
    page: UserSettings,
    title: "Configurações",
    isPublic: false,
    template: BlankTemplate,
    withNavigation: true,
    mobileMenu: true,
  },

  // COMPANY PAGES

  {
    path: "/inicio",
    page: HomePage,
    title: "Início",
    isPublic: true,
    template: BlankTemplate,
    footer: true,
    withNavigation: true,
    mobileMenu: true,
  },

  {
    path: "/chat",
    page: Chat,
    title: "Suporte Online",
    isPublic: true,
    template: BlankTemplate,
    mobileMenu: true,
    footer: true,
  },

  {
    path: "/discounts",
    page: Discounts,
    title: "Descontos",
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: "/termos-e-condicoes",
    page: Terms,
    title: "Terms",
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: "/",
    page: Discover,
    title: "Discover",
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: "/selecionar-shopping",
    page: SelectShopping,
    title: "Selecione o Shopping",
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: "/category",
    page: Categorie,
    title: "Categorias",
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: "/checkout/rating-screen",
    page: RatingScreenData,
    title: "Avalie seu pedido",
    isPublic: true,
    template: BlankTemplate,
    footer: true,
  },
  {
    path: "/minha-conta/meus-pedidos/:id",
    page: MyOrders,
    title: "Meus Pedidos",
    isPublic: false,
    template: BlankTemplate,
    mobileMenu: true,
  },
];
