import { useState } from "react";
import Swal from "sweetalert2";
import * as servicesPagarme from "../../services/pagarme";

export const usePagarme = (callback) => {
  const [cards, setCards] = useState([]);
  const [loadings, setLoadings] = useState({
    cards: false,
    createCard: false,
  });

  const getCards = async () => {
    setCards([]);
    try {
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos buscando os cartões cadastrados",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      setLoadings({ ...loadings, cards: true });
      const cards = await servicesPagarme.getCards();
      setCards(cards);
      Swal.close();
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Parece que você não tem cartões cadastrados",
        text: "Cadastre um cartão para continuar com o pagamento",
        icon: "info",
        confirmButtonText: "OK",
      });
    } finally {
      setLoadings({ ...loadings, cards: false });
    }
  };

  const createCard = async (card, type) => {
    try {
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos cadastrando o cartão",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      setLoadings({ ...loadings, createCard: true });
      await servicesPagarme.createCard(card, type);
      await getCards();
      Swal.fire({
        title: "Cartão cadastrado com sucesso",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Erro ao cadastrar o cartão",
        text: "Ocorreu um erro ao cadastrar o cartão, por favor tente novamente",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoadings({ ...loadings, createCard: false });
    }
  };

  const createOrder = async (items, method, cardId, onSuccess) => {
    try {
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos criando o pedido",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      const data = await servicesPagarme.createOrder(items, method, cardId);
      Swal.fire({
        title: "Pedido criado com sucesso",
        icon: "success",
        confirmButtonText: "OK",
      });

      if (onSuccess) {
        onSuccess();
      }

      if (callback) {
        if (method === "credit_card") {
          callback(`/checkout/sucesso/${data?.id}`);
        } else {
          localStorage.setItem(
            "orderQrCode",
            data?.orderPagarme?.charges[0]?.lastTransaction.qrCode
          );
          localStorage.setItem(
            "orderQrCodeDateGenerated",
            new Date().toISOString()
          );
          callback(`/checkout/pix/${data?.id}`);
        }
      }
      return data;
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Erro ao criar o pedido",
        text: "Ocorreu um erro ao criar o pedido, por favor tente novamente",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return { cards, loadings, getCards, createCard, createOrder };
};
