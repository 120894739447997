import styled from 'styled-components';

export const AccompanimentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 0.8em;
  padding: 2em;
`;

export const AccompanimentItem = styled.div`
  display: flex;
  padding: 1.5em 2em;
  justify-content: space-between;
  align-items: center;
  border-radius: 9px;
  background: #f1f1f1;
`;
export const ContentInfos = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const AccompanimentName = styled.span`
  flex-grow: 1;
  font-size: 1.2rem;
  font-weight: 500;
  color: #666;
`;

export const QuantitySelector = styled.div`
  display: flex;
  align-items: center;
`;

export const QuantityButton = styled.button`
  border: none;
  background-color: #fff; // Light grey for buttons
  color: #fa0c0c;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none; // Prevent text selection on double click
  transition: all 0.2s ease-in-out;
  svg {
    font-size: 1.5rem;
  }
  &:disabled {
    background-color: #00000040; // Darker grey when disabled
    cursor: not-allowed;
    border: 1px solid #00000040;
    color: #00000040;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #fa0c0c;
    color: #fff;
  }
`;

export const QuantityAmount = styled.span`
  min-width: 20px;
  text-align: center;
  font-size: 1rem;
`;

export const AccompanimentPrice = styled.span`
  font-size: 1rem;
  color: #666;
`;

export const Header = styled.div`
  display: flex;
  border-radius: 9px;
  background-color: #fa0c0cff;
  padding: 2em;
  color: #fff;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 90%;
  margin: 3em auto 0;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0px;
`;
export const Title = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    font-size: 18px;
  }
`;

export const SubTitle = styled.span`
  font-size: 1rem;
  color: #fff;
`;
