import styled from 'styled-components';

export const SectionContainerComplete = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2em 3em 4em 0em;
  overflow-x: hidden;
`;
export const SliderContainer = styled.div`
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  gap: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 2em;
  padding-right: 7em;
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
`;

export const CategoryButton = styled.button`
  border: none;
  cursor: pointer;
  min-width: 140px;
  padding: 0 0 1em 0;
  border-radius: 12px;
  align-items: center;
  background-color: #fff;
  transition: all 0.2s ease;
  &:hover {
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
    scale: 1.1;
  }
`;

export const CategoryImage = styled.img`
  width: 100%;
  height: auto;
  margin-right: 10px; // Add some space between the image and the text
`;

export const CategoryName = styled.p`
  font-size: 1.4rem;
  color: #666;
  margin: 0;
  margin-top: 0.5em;
`;

export const Title = styled.h2`
  font-size: 1.6rem;
  color: #666;
  margin: 0;
  font-weight: 500;
  margin-top: 1.4em;
  padding: 0 1em 0 2em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: 1.6em;
    color: #666;
  }
`;
