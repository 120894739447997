import styled from 'styled-components';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  padding: 0 2em;
  width: 100%;
  text-decoration: none;
`;

export const RestaurantCard = styled.div`
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: var(--White, #fff);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  width: 100%;
  padding: 1em 1em;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 1;

  svg {
    font-size: 24px;
    color: #707070;
  }

  transition: all 0.2s ease;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
    scale: 1.01;

    /* svg {
      color: #fa0c0c;
      scale: 1.1;
      transform: translateX(8px);
      transition: all 0.2s ease;
    } */
  }
`;

export const RestaurntInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0em;
`;

export const RestaurantInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4em;
  min-width: 65%;
`;

export const RestaurantName = styled.h3`
  font-size: 1.3rem;
  margin: 0 0 5px 0;
  color: #000;
  text-decoration: none;
  font-weight: 500;
`;

export const RestaurantDistance = styled.p`
  font-size: 1.2rem;
  color: #000;
  display: flex;
  border-radius: 12px;
  text-decoration: none;
  gap: 0.5em;

  svg {
    font-size: 14px;

    &:hover {
      transform: none;
    }
  }
`;

export const RestaurantStatus = styled.span`
  font-size: 1rem;
  color: #ffffff;
  background: #30cf88;
  border-radius: 51px;
  display: flex;
  justify-content: space-between;
  gap: 0.8em;
  padding: 0.6em 1em;

  svg {
    color: #fff;
    font-size: 14px;
  }
`;

export const RightArrow = styled.img`
  width: 24px;
  height: 24px;
`;

export const RestaurantImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  margin-right: 1em;
  object-fit: cover;
`;

export const SectionContainerComplete = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2em 0 2em 0em;
  overflow-x: hidden;
`;

export const Title = styled.h2`
  font-size: 1.6rem;
  color: #666;
  margin: 0;
  font-weight: 500;
  margin-top: 1em;
  padding: 1em 2em 0 2em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: 1.6em;
    color: #666;
  }
`;

export const DataInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  min-width: 65%;
`;
