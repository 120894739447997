import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
`

export const FilterContainer = styled.div`
  padding-top: 2em;
  display: flex;
  padding-bottom: 2em;
  padding-left: 2em;
  margin-bottom: 20px;
  padding-right: 4em;
  gap: 0.8em;
  overflow-x: scroll;
  position: sticky;
  z-index: 30;
  top: 6.8em;
  background: rgb(249, 249, 249);
  background: linear-gradient(
    0deg,
    rgba(249, 249, 249, 0) 0%,
    rgba(249, 249, 249, 1) 100%
  );
  // add scroll horizontal with swipe
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  // hide scrollbar
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  

  
`;

export const FilterTab = styled.div`
  padding: 10px 20px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  gap: 0.8em;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: ${({ selected }) => (selected ? '#fff' : '#707070')};
  background-color: ${({ selected }) => (selected ? '#FA0C0C' : '#fff')};

  svg {
    font-size: 20px;
    color: ${({ selected }) => (selected ? '#fff' : '#707070')};
  }
`;

export const Title = styled.h2`
  font-size: 1.6rem;
  color: #666;
  margin: 0;
  font-weight: 500;
  margin-top: 1.4em;
  padding: 0 2em 1em 1.3em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: 1.6em;
    color: #666;
  }
`;
