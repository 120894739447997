import React, { useEffect, useState } from "react";
import * as S from "./styles";

const Header = () => {
  const [isVisible, setIsVisible] = useState(true);


  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setIsVisible((prevScrollPos) => currentScrollPos <= prevScrollPos);
  };

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    window.addEventListener("scroll", () => {
      handleScroll();
      prevScrollPos = window.pageYOffset;
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <S.Header isVisible={isVisible}>
      </S.Header>
    </>
  );
};

export default Header;
