// styles.js
import styled from "styled-components";

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;
  border-radius: 0px 0px 15px 15px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  position: sticky;
  margin-bottom: -1.4em;
  top: 0;
  z-index: 1;
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;

  height: 50px;
  width: 50px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    background: #f2f2f2;
    box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.15);
  }
`;

export const Title = styled.h1`
  font-size: 18px;

  font-weight: 600;
  color: #666;
`;

export const HomeIcon = styled.img`
  width: 25px;
`;
export const HomeButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    scale: 1.2;
  }
  /* Style your button with a home icon */
`;

export const CartButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: relative;

  svg {
    font-size: 3.3em;
    color: #666;
  }
`;

export const CartItemCount = styled.span`
  position: absolute;
  top: -10px; // Adjust as needed
  right: -10px; // Adjust as needed
  background-color: #fa0c0c;
  color: #fff;
  font-size: 0.75em;
  width: 20px; // Adjust as needed
  height: 20px; // Adjust as needed
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
