import styled from "styled-components";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 140px 3em 0 3em;
  gap: 1em;
  background-color: #f00; // Vermelho
  color: #fff; // Branco
  height: 100vh;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${(props) => (props.slideIn ? "0" : "-100%")});
`;

export const StepImage = styled.img`
  border: none;
  border-radius: 20px;
  max-width: 100%;
  /* height: auto; */
  /* max-height: 350px; */
  /* min-height: 350px; */
  margin-bottom: 5em;
  animation: fadeIn 0.8s ease-out;

  &:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const StepTitle = styled.h2`
  font-size: 18px;
  animation: fadeIn 1s ease-out;
  text-align: center;
  font-weight: 500;
`;

export const StepText = styled.p`
  font-size: 18px;
  text-align: center;
  margin-top: 1em;
  animation: fadeIn 1.2s ease-out;
`;

export const NavigationButton = styled.button`
  border: none;
  background: linear-gradient(
    180deg,
    rgb(101 43 43 / 55%) 0%,
    rgb(112 42 42 / 52%) 100%
  );
  box-shadow: 2px 2px 30px 0px rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(10px);
  border-radius: 0px;
  padding: 2em 2em;
  margin-top: 1em;
  position: absolute;
  bottom: 0em;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #fff;
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: space-between;
  width: 100%;

  svg {
    font-size: 24px;
  }
  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: scale(1.04);
    transition: transform 0.2s;
  }
`;

export const SkipButton = styled.button`
  background: none;
  border: none;
  color: #ffffff; // Branco semi-transparente
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  bottom: 110px;

  &:hover {
    color: #ffffff; // Branco
    transition: color 0.3s ease-in;
  }
`;

export const PaginationDots = styled.div`
  position: absolute;
  bottom: 60px;
  display: flex;
`;

export const Dot = styled.span`
  height: 10px;
  width: 10px;
  background-color: ${(props) => (props.active ? "#fff" : "#bbb")};
  border-radius: 50%;
  margin: 0 5px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

export const Logo = styled.img`
  margin-bottom: 10em;

  max-width: 200px;
`;
