import styled from 'styled-components';

export const Container = styled.div``;

export const ItemImage = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;

  border-radius: 0 0 15px 15px;
`;

export const ProductImage = styled.img`
  width: 100px; // example width
  height: 100px; // example height
  margin-right: 20px; // example margin
`;
