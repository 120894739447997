// CPFInput.js
import React from 'react';
import * as S from './styles'; // Assuming styles.js is in the same directory

export const CPFInput = ({ cpf, onCPFChange }) => {
  return (
    <S.CPFInputContainer>
      <S.CPFInputLabel htmlFor='cpfInput'>CPF na nota</S.CPFInputLabel>
      <S.CPFInputField
        id='cpfInput'
        name='cpf'
        type='text'
        value={cpf}
        onChange={onCPFChange}
        placeholder='000.000.000-00'
        maxLength='14'
      />
    </S.CPFInputContainer>
  );
};

export default CPFInput;
