// CompleteOrderButton.js
import React from 'react';
import { MdOutlineChecklist } from 'react-icons/md';
import * as S from './styles';

export const CompleteOrderButton = ({ onClick }) => {
  // const HandleRedirect = () => {
  //   window.location.href = '/checkout/thanks';
  // };
  return (
    <S.CompleteOrderButtonStyled
      onClick={onClick}
    >
      Finalizar meu pedido <MdOutlineChecklist />
    </S.CompleteOrderButtonStyled>
  );
};

export default CompleteOrderButton;
