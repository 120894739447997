import MyOrdersList from '../../../components/MyOrdersList';
import * as S from './styles';

const MyOrders = () => {
  return (
    <S.Container>
      <MyOrdersList />
    </S.Container>
  );
};

export default MyOrders;
