import styled from "styled-components";

export const ProductContainer = styled.div`
  padding-bottom: 20rem;
`;

export const ItemImage = styled.img`
  width: 100%;
  height: 280px;
  object-fit: cover;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;

  flex-direction: column;
  justify-content: flex-start;
`;

export const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 8px;
  background: var(--White, #fff);
  gap: 1.6em;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
  padding: 2em;
  width: 90%;
  margin: -20px auto;
  z-index: 999;
  position: relative;
`;

export const ObsContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 8px;
  background: var(--White, #fff);
  gap: 1.6em;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  padding: 2em;
  width: 90%;
  margin: -20px auto;
  margin-top: 3em;
  z-index: 999;
  position: relative;
`;

export const ObsTitle = styled.h2`
  font-size: 1.3em;
`;

export const ObsInput = styled.input`
  border: none;
  border: 0.5px solid grey;
  height: 5em;
  border-radius: 10px;
`;

export const ProductTitle = styled.h2`
  font-size: 1.5rem;
`;

export const ProductPrice = styled.span`
  font-size: 1.5rem;
  color: red;
`;

export const ProductDescription = styled.p`
  color: gray;
`;

export const ProductDetails = styled.p`
  margin-bottom: 00px;
`;

export const QuantitySelector = styled.div`
  display: flex;
  align-items: center;
`;

export const QuantityButton = styled.button`
  padding: 5px 10px;
  margin: 0 10px;
  border: none;
  width: 50px;
  background-color: #00000010;
  border-radius: 12px;
  height: 50px;
  // Add more styles as needed
`;

export const QuantityAmount = styled.span`
  font-size: 1.5rem;
  min-width: 20px;
  text-align: center;
`;

export const AddBar = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 10rem;
  top: 76%;
  bottom: 0;
  gap: 1em;
  left: 0;
  align-items: center;
  padding: 2em 2em 3em 2em;
  justify-content: space-between;
  border-radius: 12px 12px 0px 0px;
  background: #f6f6f6;
  box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.12);
`;

export const AddButton = styled.button`
  width: 90%;
  height: 50px;
  padding: 0 2em;
  margin: auto;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-radius: 13px;
  background: var(--Green, #01c59c);
  color: white;
  border: none;

  svg {
    font-size: 2.6rem;
  }
`;
