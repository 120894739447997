import React, { useEffect, useState } from "react";
import { IoChevronDown, IoLocationOutline } from "react-icons/io5";
import * as S from "./styles";

const LocationSelector = ({ selectedHangar, hangars, selectValue }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleShoppingSelect = (hangar) => {
    selectValue(hangar);
    setDropdownOpen(false);
  };

  const hangar = hangars.find((hangar) => hangar.id === selectedHangar?.id);

  return (
    <S.LocationContainer>
      <S.Dropdown>
        <S.DropdownHeader onClick={toggleDropdown}>
          <S.Content>
            <IoLocationOutline size={20} />
            {hangar?.title || "Selecione um Shopping"}
          </S.Content>

          <IoChevronDown />
        </S.DropdownHeader>
        {dropdownOpen && (
          <S.DropdownList>
            {hangars?.map((hangar) => (
              <S.DropdownItem
                key={hangar?.id}
                onClick={() => handleShoppingSelect(hangar)}
              >
                <S.Content>
                  <IoLocationOutline size={20} />
                  {hangar?.title}
                </S.Content>
                <S.DistanceNotification>{hangar?.distanceFormatted}</S.DistanceNotification>
              </S.DropdownItem>
            ))}
          </S.DropdownList>
        )}
      </S.Dropdown>
    </S.LocationContainer>
  );
};

export default LocationSelector;
