// index.js
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Swal from 'sweetalert2';
import * as S from './styles'; // Import all styled components as S
import { useNavigate, useParams } from 'react-router-dom';
import { useWebSocket } from '../../hooks/useWebSocket';
import useMe from '../../hooks/useMe';

const countdownSequence = 15 * 60 * 1000;
const currentDateTimestamp = new Date().getTime();
const PixPageScreen = () => {
  const { id } = useParams()
  const me = useMe();

  const { joinRoom, addEventListener, removeEventListener, emitEvent } = useWebSocket();
  const [data, setData] = useState({});
  
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(countdownSequence);

  useEffect(() => {
    if(me?.id) {
      console.log('me?.id', me?.id)
      joinRoom(me?.id);
    }
  }, [me?.id, joinRoom]);

  useEffect(() => {
    const handleMessages = (message) => {
      console.log("message", message)
      setData(message);
    };

    addEventListener('ORDER_DETAIL', handleMessages);
    emitEvent("GET_ORDER_DETAIL", { id, userId: me?.id});

    return () => removeEventListener('ORDER_DETAIL', handleMessages);
  }, [me]);

  useEffect(() => {
    console.log("data", data)
    if(data?.id && data?.status === 'paid') {
      Swal.fire({
        title: 'Pedido pago!',
        text: 'Seu pedido foi pago com sucesso.',
        icon: 'success',
        confirmButtonText: 'Ok',
      }).then(() => {
        navigate(`/checkout/sucesso/${data?.id}`);
      })

    }
  }, [data]);

  console.log("data", data)


  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const qrCode = localStorage.getItem('orderQrCode');
  const qrCodeDateGenerated = localStorage.getItem('orderQrCodeDateGenerated'); // '2024-03-28T02:56:29.774Z'
  const timeLeftBasedOnDate = (new Date(qrCodeDateGenerated).getTime() - currentDateTimestamp) + countdown;
  // const timeLeft = new Date(countdown).toISOString().substr(14, 5);
  const timeLeft = new Date(timeLeftBasedOnDate).toISOString().substr(14, 5);

  const timesUp = timeLeftBasedOnDate <= 0;

  const handleCopied = () => {
    Swal.fire({
      title: 'Código copiado!',
      text: 'Agora é só colar no app do seu banco e efetuar o pagamento.',
      icon: 'success',
      confirmButtonText: 'Entendi',
    });
  }

  return (
    <S.ScreenContainer>
      {timesUp ? (
        <>
          <S.Title>
            Seu código expirou!
          </S.Title>
          <S.Subtitle>
            Por favor, realize um novo pedido.
          </S.Subtitle>
          <S.Button onClick={() => navigate('/inicio')}>Ir para o início</S.Button>
        </>
      ) : (
        <>
          <S.Title>
            Para finalizar o pedido, pague com PIX abaixo.
          </S.Title>
          <S.Subtitle>
            Copie a chave PIX e faça o pagamento.
          </S.Subtitle>
          <CopyToClipboard text={qrCode}
            onCopy={handleCopied}>
            <S.OrderCode>{qrCode}</S.OrderCode>
          </CopyToClipboard>

          <CopyToClipboard text={qrCode}
            onCopy={handleCopied}>
            <S.Button>Copiar código</S.Button>
          </CopyToClipboard>

          <S.Title>
            O código irá expirar em: {timeLeft}
          </S.Title>
        </>
      )}
    </S.ScreenContainer>
  );
};

export default PixPageScreen;
