import { Helmet } from "react-helmet";
import {
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import Footer from "./components/Footer";
import MobileMenu from "./components/MobileMenu";
import NavigateHeader from "./components/NavigateHeader";
import { routes } from "./routes";

import * as S from "./styles";

function AppContent() {
  return (
    <>
      <Routes>
        {routes.map((route, index) => {
          const Template = route.template;
          const Page = route.page;
          const title = route.title;

          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Template isPublic={route.isPublic}>
                  <Helmet title={`E-pedido ${title}`} />

                  {
                    !!route.withNavigation && (
                      <NavigateHeader logo={<S.Logo />} />
                    )
                  }

                  {
                    !!route.mobileMenu && (
                      <MobileMenu />
                    )
                  }

                  <Page />

                  {
                    !!route.footer && (
                      <Footer />
                    )
                  }
                </Template>
              }
            />
          );
        })}
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
