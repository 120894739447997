// src/components/UserAccountMenu/styles.js
import styled from "styled-components";

export const AccountMenuContainer = styled.div`
  padding: 2em;
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 3em;
`;

export const BackToHome = styled.div`
  color: #000;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
`;

export const CreditsInfo = styled.div`
  background: #e6fff8;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 20px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const MenuItem = styled.div`
  background: #f9f9f9;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 16px;
  height: 65px;
  background-color: #f2f2f2;
  padding: 3em 2em;
  width: 100%;
  transition: all 0.3s ease;

  &:hover {
    background-color: #fff;
  }
`;

export const MenuText = styled.span`
  margin-left: 10px;
`;

export const MenuIcon = styled.img`
  // Adjustments can be made for specific icons if needed
`;
