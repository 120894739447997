import styled from 'styled-components';

export const GreetingContainer = styled.div`
  text-align: center;

  color: white;
  text-align: left;
  gap: 1em;
`;

export const WelcomeMessage = styled.h1`
  margin: 1em 0 0;
  padding: 0;
  font-weight: 600;
`;

export const SubMessage = styled.p`
  margin: 0;
  font-size: 18px;
  padding: 0;
`;
