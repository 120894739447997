import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../BackButton";
import * as S from "./styles";
import { TOKEN_NAME } from "../../env";
import { BiCart, BiLogOut } from "react-icons/bi";
import { RiProfileFill } from "react-icons/ri";

const UserAccountMenu = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_NAME);

    if (!token) {
      navigate("/minha-conta");
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem(TOKEN_NAME);
    navigate("/minha-conta");
  };

  const navigateToMyTickets = () => {
    navigate("/minha-conta/meus-pedidos");
  };

  const navigateToSettings = () => {
    navigate("/minha-conta/configuracoes");
  };

  return (
    <>
      <S.AccountMenuContainer>
        <S.MenuItem onClick={navigateToMyTickets}>
          <S.MenuText>Seus Pedidos</S.MenuText>
          <BiCart size={30} color="grey" />
        </S.MenuItem>

        {/* <S.MenuItem onClick={navigateToSettings}>
          <S.MenuText>Ofertas</S.MenuText>
          <S.MenuIcon src={settingsIcon} />
        </S.MenuItem> */}

        <S.MenuItem onClick={navigateToSettings}>
          <S.MenuText>Meu Perfil</S.MenuText>
          <RiProfileFill size={30} color="grey" />
        </S.MenuItem>

        <S.MenuItem onClick={handleLogout}>
          <S.MenuText>Sair da minha conta</S.MenuText>
          <BiLogOut size={30} color="grey" />
        </S.MenuItem>
      </S.AccountMenuContainer>
    </>
  );
};

export default UserAccountMenu;
