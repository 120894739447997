import React from 'react';
import ProductDetail from '../../../../components/ProductDetail';

const FoodPage = () => {
  return (
    <>
      <ProductDetail />
    </>
  );
};

export default FoodPage;
