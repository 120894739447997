// src/components/MobileMenu/styles.js
import styled from "styled-components";

export const Container = styled.nav`
  width: 480px;
`;

export const Content = styled.div`
  position: fixed;
  bottom: 0;
  box-shadow: -1px 0px 40px #5f5f5f4a;

  max-width: 480px;

  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
  z-index: 1000;
  border-radius: 45px 45px 0 0;
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
  gap: 1em;
`;

export const MenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  height: 58px;
  margin-top: ${({ isActive }) => (isActive ? "-10px" : "0")};
  width: 58px;
  cursor: pointer;
  transition: all 0.3s ease;

  border: ${({ isActive }) =>
    isActive ? "2px solid #FA0C0C" : "2px solid #f2f2f2"};
  background: ${({ isActive }) => (isActive ? " #FA0C0C" : "none")};
  border-radius: 50%;

  &:hover {
    border: 1px solid #fa0c0c;
    margin-top: -10px;
  }

  svg {
    font-size: 28px;
    color: ${({ isActive }) => (isActive ? "white" : "none")};
  }
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
