// styles.js
import styled from "styled-components";

export const ScreenContainer = styled.div`
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 9em 2em 2em;
  background-color: ${(props) => (props.isReady ? "#000" : "#FA0C0C")};
`;

export const StatusIcon = styled.div`
  font-size: 100px;
  margin: 16px 0;
`;

export const OrderCode = styled.div`
  color: white;
  width: 100%;
  text-align: center;
  font-size: 40px;
  letter-spacing: 2px;
  margin: 0px 0;
  border-radius: 10px;

  margin-top: 1em;
  margin-bottom: 1em;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1em;
  cursor: pointer;
`;

export const Title = styled.h1`
  font-size: 32px;
  padding: 0 1em;
  margin-bottom: 1em;
  line-height: 32px;
  font-weight: 500;
  text-align: center;
`;

export const Subtitle = styled.p`
  font-size: 18px;
  text-align: center;
`;
