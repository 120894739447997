// AccountToggle.js
import React, { useState } from "react";
import { PiCirclesThreePlusDuotone, PiUserPlusDuotone } from "react-icons/pi";
import Login from "../../components/User/Login";
import Register from "../../components/User/Register";
import * as S from "./styles";

const AccountToggle = ({ redirect }) => {
  const [isLogin, setIsLogin] = useState(true);

  return (
    <S.Container>
      <S.ImageBanner>
        <S.ToggleContainer>
          <S.ToggleButton
            onClick={() => setIsLogin(true)}
            active={isLogin}
            left
          >
            <PiCirclesThreePlusDuotone />
            Entrar
          </S.ToggleButton>
          <S.ToggleButton
            onClick={() => setIsLogin(false)}
            active={!isLogin}
            right
          >
            <PiUserPlusDuotone />
            Cadastro
          </S.ToggleButton>
        </S.ToggleContainer>
      </S.ImageBanner>
      {isLogin ? (
        <Login redirect={redirect} />
      ) : (
        <Register redirect={redirect} />
      )}
    </S.Container>
  );
};

export default AccountToggle;
