import styled from "styled-components";

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  padding: 02em;
  padding-bottom: 15em;
`;

export const ProductCard = styled.div`
  background-color: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  border-radius: 12px;
  position: relative;
  gap: 1.2em;
`;

export const ProductName = styled.h4`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
`;

export const ProductDescription = styled.p`
  color: #666;
  font-size: 12px;
  margin: 0;
`;

export const ProductPrice = styled.p`
  color: #000;
  position: absolute;
  right: 2em;
  top: 2em;
  background-color: #f2f2f2;

  padding: 0.4em 0.8em;
  border-radius: 5px;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

export const EmptyList = styled.p`
  text-align: center;
  font-size: 16px;
  color: #666;
  margin-top: 2em;
`;
