import React, { useCallback, useEffect, useState } from "react";
import AddCreditCardButton from "./AddCreditCardButton";
import { CPFInput } from "./CPFInput";
import { CartItem } from "./CartItem";
import { CompleteOrderButton } from "./CompleteOrderButton";
import ItemAdditionButton from "./ItemAdditionButton";
import { OrderSummary } from "./OrderSummary";
import { PaymentMethod } from "./PaymentMethod";
import * as S from "./styles";
import { useCartContext } from "../../context/CartContext";
import { BiCheck, BiLogIn, BiSad } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { SlPlus } from "react-icons/sl";
import useLoggedIn from "../../hooks/useLoggedIn";
import { usePagarme } from "../../hooks/usePagarme";

const OrderTrackingScreen = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useLoggedIn();
  const [paymentMethod, setPaymentMethod] = useState("credit_card");

  const { cards, createCard, getCards, createOrder } = usePagarme(navigate);
  const [cardSelected, setCardSelected] = useState(null);

  const {
    cart,
    updateProductQuantity,
    getTotalPrice,
    removeProduct,
    clearCart,
  } = useCartContext();

  useEffect(() => {
    if (isLoggedIn && paymentMethod === "credit_card") {
      getCards();
    }
  }, [isLoggedIn, paymentMethod]);

  useEffect(() => {
    console.log(cards);
    if (cards.length) {
      setCardSelected(cards[0].id);
    }
  }, [cards]);

  useEffect(() => {
    if (paymentMethod === "pix") {
      setCardSelected(null);
    }
  }, [paymentMethod]);

  const handleCreateOrder = useCallback(async () => {
    try {
      await createOrder(cart, paymentMethod, cardSelected, clearCart);
    } catch (error) {
      console.error(error);
    }
  }, [paymentMethod, cardSelected, cart, clearCart]);

  return (
    <S.TrackingContainer>
      {cart.length === 0 ? (
        <S.EmptyCart>
          <BiSad size={50} />
          <S.EmptyCartText>Seu carrinho está vazio</S.EmptyCartText>
          <S.AdditionButton
            onClick={() => navigate(-1)}
            style={{
              marginTop: 20,
            }}
          >
            Clique aqui para adicionar itens &nbsp;
            <SlPlus />
          </S.AdditionButton>
        </S.EmptyCart>
      ) : (
        <>
            {console.log("cart", cart)}
          {cart?.map((item, index) => (
            <CartItem
              key={index}
              index={index}
              item={item}
              updateProductQuantity={updateProductQuantity}
              removeProduct={removeProduct}
            />
          ))}
          <ItemAdditionButton clearCart={clearCart} />
          <OrderSummary subtotal={getTotalPrice()} total={getTotalPrice()} />
          {/* <PickupAddress address="Rua Luis Antonio, 82" onEdit={() => {}} /> */}

          {isLoggedIn ? (
            <>
              <PaymentMethod
                selectedMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
              />

              {!!cards?.length && paymentMethod === "credit_card" && (
                <S.CreditCardList>
                  {cards.map((card) => (
                    <S.CreditCard
                      key={card.id}
                      isSelected={card.id === cardSelected}
                      onClick={() => setCardSelected(card.id)}
                    >
                      <S.CardBrand>
                        {card.id === cardSelected && <BiCheck size={15} />}
                        {card.brand === "visa" ? "Visa" : "Mastercard"}
                      </S.CardBrand>
                      <S.CardNumber>
                        **** **** **** {card.lastFourDigits}
                      </S.CardNumber>
                    </S.CreditCard>
                  ))}
                </S.CreditCardList>
              )}

              {paymentMethod === "credit_card" && (
                <AddCreditCardButton cards={cards} createCard={createCard} />
              )}
              <CPFInput cpf={""} />
              <CompleteOrderButton onClick={() => handleCreateOrder()} />
            </>
          ) : (
            <S.AdditionButton
              onClick={() => navigate("/minha-conta?redirect=/checkout")}
              style={{
                color: "#fff",
                backgroundColor: "#f00",
              }}
            >
              Faça login para continuar <BiLogIn />
            </S.AdditionButton>
          )}
        </>
      )}
    </S.TrackingContainer>
  );
};

export default OrderTrackingScreen;
