// ItemAdditionButton.js
import React from 'react';
import { SlPlus } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import * as S from './styles'; // Import your styled component
import { BiTrash } from 'react-icons/bi';
import Swal from 'sweetalert2';

const ItemAdditionButton = ({ clearCart }) => {
  const navigate = useNavigate();

  const goBackToAddItems = () => {
    navigate(-1);
  };

  const handleClearCart = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Deseja limpar o carrinho?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      icon: 'warning',
    });

    if(isConfirmed) {
      await Swal.fire({
        title: 'Carrinho limpo com sucesso!',
        icon: 'success',
      });
      clearCart()
      navigate(-1);
    }
  }

  return (
    <>
      <S.AdditionButton isTrash onClick={handleClearCart}>
        Limpar carrinho
        <BiTrash />
      </S.AdditionButton>

      <S.AdditionButton onClick={goBackToAddItems} style={{ color: '#fff' }}>
        Adicionar mais itens
        <SlPlus />
      </S.AdditionButton>
    </>
  );
};

export default ItemAdditionButton;
