import styled from 'styled-components';
export const Body = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  padding: 2em;
  width: 100%;
  display: flex; /* Added display flex */
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 15em;
`;

export const HeadingBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4em;

  z-index: 1;
`;

export const Title = styled.h2`
  color: #000;
  text-align: center;
  font-size: 21px;
  font-style: normal;
  margin: 0;
  font-weight: 700;
  line-height: 21px; /* 80.769% */
`;

export const SubTitle = styled.h5`
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  margin: 0;
  font-weight: 300;

  line-height: normal;
`;
export const SendButton = styled.button`
  display: flex;
  width: 100%;
  color: #fa0c0c;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border-radius: 15px;
  border: 0.925px solid #fa0c0c;
  background: #fff;
  box-shadow: 0px 0px 8.651px 0px rgba(0, 0, 0, 0.05);
  height: 50px;
  padding: 1em 2em;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  svg {
    font-size: 26px;
  }

  &:hover {
    box-shadow: none;
    color: #ffffff;
    transition: all 0.3s ease;
    background: #fa0c0c;
  }
`;

export const Logo = styled.img`
  width: 230px;
  z-index: 1;
  height: auto;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2em;
`;

export const LogoBackgroundAvatar = styled.img`
  width: auto;
  height: 100%;
  position: absolute;
  z-index: 0;
  bottom: 0px;
  right: 0px;
`;

export const Forms = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  margin: 0em auto;
  justify-content: center;
  z-index: 1;
  align-items: center;
  max-width: 460px;
  @media screen and (max-width: 600px) {
    max-width: 100%;
    padding: 0;
  }

  .error {
    color: #fa0c0c;
    margin-left: 0.3rem;
    margin-right: auto;
    margin-bottom: 1rem;
  }

  .input {
    margin-bottom: 0.5rem;
  }
`;

export const InputGroup = styled.div`
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    left: 20px;
    top: 49%;
    transform: translateY(-50%);
    color: #aaa;
    font-size: 22px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 1.4em;
  padding-left: 60px; // Make space for the icon
  border-radius: 5px;
  border: 1px solid #ddd;
  height: 50px;
  font-size: 16px;
  border-radius: 15px;
  border: 1px solid #dfdfdf;
  background: var(--White, #fff);
`;
