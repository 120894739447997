import React from "react";
import { BsChevronCompactRight } from "react-icons/bs";
import { IoRestaurantOutline } from "react-icons/io5";
import { MdOutlineWatchLater } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";

const RestaurantList = ({ restaurants }) => {
  const navigate = useNavigate()
  const isActive = (value) => {
    if (value === true) {
      return "Aberto";
    } else {
      return "Fechado";
    }
  };

  const handleRestaurantClick = (restaurant) => {
    localStorage.setItem('restaurant', JSON.stringify(restaurant))
    navigate(`/restaurante/${restaurant.slug}/${restaurant.id}`)
  }

  return (
    <>
      <S.Title>
        Restaurantes
        <IoRestaurantOutline />
      </S.Title>
      <S.SectionContainerComplete>
        <S.ListContainer>
          {restaurants?.map((restaurant, index) => (
            <S.RestaurantCard key={`restaurante-${restaurant.id}-item-${index}`} onClick={() => handleRestaurantClick(restaurant)}>
              <S.RestaurantInfo>
                <S.RestaurantImage
                  src={restaurant?.logo?.url || '/placeholder-item.jpg'}
                  alt="Restaurant image"
                />
                <S.RestaurantName>
                  {restaurant?.title}
                </S.RestaurantName>
              </S.RestaurantInfo>
              <S.RestaurantStatus>
                <MdOutlineWatchLater />
                {isActive(restaurant?.active)}
              </S.RestaurantStatus>
              <BsChevronCompactRight />
            </S.RestaurantCard>
          ))}
        </S.ListContainer>
      </S.SectionContainerComplete>
    </>
  );
};

export default RestaurantList;
