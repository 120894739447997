import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { TbPaperBag } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";
import { useCartContext } from "../../context/CartContext";

const NavigateHeader = ({ pageTitle, logo }) => {
  const { getTotalItems } = useCartContext();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const goToCart = () => {
    navigate("/checkout");
  };

  const cartItemCount = getTotalItems();

  return (
    <S.HeaderContainer>
      <S.BackButton onClick={goBack}>
        <IoIosArrowBack />
      </S.BackButton>
      <S.Title>{logo ? logo : pageTitle}</S.Title>
      <S.CartButton onClick={goToCart}>
        <TbPaperBag />
        {cartItemCount > 0 && (
          <S.CartItemCount>{cartItemCount}</S.CartItemCount>
        )}
      </S.CartButton>
    </S.HeaderContainer>
  );
};

export default NavigateHeader;
