import OrderReadyScreen from '../../../components/OrderReadyScreen';
import * as S from './styles';

import Header from '../../../components/Header';
import { useOrders } from '../../../hooks/useOrders';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
const ThankYou = () => {
  const { id } = useParams();

  const { getOrderById, loading, order } = useOrders();

  useEffect(() => {
    if(id) {
      getOrderById(id);
    }
  }, [id]);

  useEffect(() => {
    if(loading) {
      Swal.fire({
        title: 'Buscando pedido...',
        text: `Pedido #${id}`,
        icon: 'info',
        confirmButtonText: 'Ok',
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        beforeOpen: () => {
          Swal.isLoading()
          Swal.showLoading()
        }
      })
    }

    if(!loading && order) {
      Swal.close();
    }
  }, [loading]);

  if(loading) return null;

  return (
    <>
      <Header />
      <S.Container>
        <OrderReadyScreen orderId={order?.id} orderCode={order?.code} />
      </S.Container>
    </>
  );
};

export default ThankYou;
