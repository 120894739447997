import styled from 'styled-components';

export const SectionContainerComplete = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2em 0 2em 0em;
  overflow-x: hidden;
`;
export const SliderContainer = styled.div`
  overflow-x: auto;
  display: flex;
  gap: 10px;
  padding-left: 2em;
  padding-right: 8em;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DishCard = styled.div`
  flex: 0 0 auto;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  width: 300px; // Adjust the width as needed
  height: 290px; // Adjust the height as needed
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
`;

export const DishInfo = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2em;
  width: 100%;
`;

export const DishName = styled.h3`
  margin: 0;
  color: #333;
  font-size: 1.5rem;
`;

export const DishPrice = styled.p`
  margin: 0;
  color: #666;
  margin-top: 0.2em;
  font-size: 1.4rem;
`;

export const Title = styled.h2`
  font-size: 1.6rem;
  color: #666;
  margin: 0;
  font-weight: 500;
  padding: 2em 2em 0 1.5em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: 1.6em;
    color: #666;
  }
`;
