import React, { useEffect, useState } from "react";
import { IoCardOutline } from "react-icons/io5";
import CreditCardForm from "./CreditCardForm";
import * as S from "./styles";

const AddCreditCardButton = ({ createCard }) => {
  const [type, setType] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleSave = async (card, type) => {
    await createCard(card, type);
    closeModal();
  };

  return (
    <>
      <S.Button onClick={openModal}>
        Adicionar Cartão de Crédito <IoCardOutline />
      </S.Button>
      {isModalOpen && (
        <S.Modal>
          <S.Overlay onClick={closeModal} />

          <S.ModalContent>
            <S.CloseButton onClick={closeModal}>&times;</S.CloseButton>
            <CreditCardForm onSave={handleSave} setType={setType} type={type} />
          </S.ModalContent>
        </S.Modal>
      )}
    </>
  );
};

export default AddCreditCardButton;
