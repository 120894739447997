import { IoIosArrowBack } from "react-icons/io";
import * as S from "./styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BiSolidUserCircle } from "react-icons/bi";
import { TbLock, TbPassword } from "react-icons/tb";
import { useState } from "react";
import { object, ref, string } from "yup";
import { httpService } from "../../services/axios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const logo = "/assets/header/logo-red.svg";

const schema = object().shape({
  password: string()
    .min(6, "O tamanho mínimo tem que ser no mínimo 6 digitos")
    .required("A senha é obrigatório"),
  confirmPassword: string()
    .oneOf([ref("password"), null], "As senhas não coincidem")
    .required("Você precisa confirmar a senha"),
});

const ResetPassword = () => {
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), reValidateMode: "onChange" });

  const navigate = useNavigate();

  const goHome = () => {
    navigate("/selecionar-shopping");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleResetPassword = async (values) => {
    const QueryParam = params.get("code");

    try {
      setLoading(true);
      Swal.fire({
        title: "Aguarde...",
        text: "Mudando a senha",
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      const { data: response } = await httpService.put("/auth/password", {
        ...values,
        code: QueryParam,
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Sua senha foi atualizada, retorne a página de login.",
        icon: "success",
        showConfirmButton: true,
      });
    } catch (error) {
      Swal.fire({
        title: "erro...",
        text: "Não foi posssivel redefinir a senha.",
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <S.Container>
      <S.Content>
        <S.Logo src={logo} alt="Epedido" />
        <TbLock size={80} color="red" />
        <S.Form onSubmit={handleSubmit(handleResetPassword)}>
          <S.TitleInput>Insira sua nova senha</S.TitleInput>

          <S.InputGroup>
            <TbPassword />
            <S.Input
              name="password"
              onChange={handleChange}
              placeholder="Senha"
              type="password"
              {...register("password")}
            />
          </S.InputGroup>
          {errors?.password && (
            <S.ErrorText className="error">
              {errors.password.message}
            </S.ErrorText>
          )}
          <S.TitleInput>Insira sua nova senha novamente</S.TitleInput>

          <S.InputGroup>
            <TbPassword />
            <S.Input
              name="confirmPassword"
              onChange={handleChange}
              placeholder="Confirme a senha"
              type="password"
              {...register("confirmPassword")}
            />
          </S.InputGroup>
          {errors?.confirmPassword && (
            <S.ErrorText className="error">
              {errors.confirmPassword.message}
            </S.ErrorText>
          )}

          <S.SendButton disabled={loading}>Enviar</S.SendButton>
        </S.Form>
        <S.BackButton onClick={goHome}>Voltar a o início</S.BackButton>
      </S.Content>
    </S.Container>
  );
};
export default ResetPassword;
