import React, { useCallback, useEffect, useState } from "react";
import { BiCartAdd } from "react-icons/bi";
import { useParams } from "react-router-dom";
import AccompanimentSelector from "../AccompanimentSelector";
import * as S from "./styles";
import { useCartContext } from "../../context/CartContext.js";
import { useProducts } from "../../hooks/useProducts";
import { formatCurrency } from "../../utils/currency.js";
import Loading from "../Loading/index.js";

const defaultCart = {
  product: null,
  quantity: 0,
  additionalsDish: [],
};

const ProductDetail = () => {
  const { addProduct } = useCartContext();
  const { getProductById, getSideDishes, loadings, product, sideDishes } =
    useProducts();
  const { productId } = useParams();
  const [observation, setObservation] = useState("");
  const [mainDishQuantity, setMainDishQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState();

  const [cart, setCart] = useState(defaultCart);

  useEffect(() => {
    if (product) {
      setTotalPrice(product?.price);
    }
  }, [product]);

  const whenNotAddMainDishQuantity = useCallback(
    (product, mainDishQuantity) => {
      const newProduct = {
        ...cart,
        product: product.id,
        quantity: mainDishQuantity,
        productInfo: product,
        observations: observation,
      };

      return newProduct;
    },
    [product, cart, mainDishQuantity, observation]
  );

  const handleAddProductToCart = useCallback(() => {
    const newProduct = {
      ...cart,
      product: product.id,
      quantity: mainDishQuantity,
      productInfo: product,
      observations: observation,
    };

    setCart(newProduct);
  }, [product, cart, mainDishQuantity, observation]);

  const handleAddAccompanimentToCart = (item, quantity) => {
    const alreadyAdded = cart.additionalsDish.find(
      (accompaniment) => accompaniment.side_dish === item.id
    );

    let newAdditionalsDish;

    if (alreadyAdded) {
      newAdditionalsDish = cart.additionalsDish.map((accompaniment) => {
        if (accompaniment.side_dish === item.id) {
          return {
            ...accompaniment,
            quantity,
            info: item,
          };
        }
        return accompaniment;
      });
    } else {
      newAdditionalsDish = [
        ...cart.additionalsDish,
        {
          side_dish: item.id,
          quantity,
          info: item,
        },
      ];
    }

    const newProduct = {
      ...cart,
      additionalsDish: newAdditionalsDish,
    };

    setCart(newProduct);
  };

  const getTotalFromCart = useCallback(() => {
    const mainDishPrice = product?.price * mainDishQuantity;
    const accompanimentsPrice = cart.additionalsDish.reduce(
      (acc, accompaniment) => {
        const sideDish = sideDishes.find(
          (sideDish) => sideDish.id === accompaniment.side_dish
        );
        return acc + sideDish.price * accompaniment.quantity * mainDishQuantity;
      },
      0
    );

    setTotalPrice(mainDishPrice + accompanimentsPrice);
    return mainDishPrice + accompanimentsPrice;
  }, [mainDishQuantity, product, cart, sideDishes]);

  const handleQuantityChange = (increment) => {
    setMainDishQuantity((prev) => prev + increment);
  };

  useEffect(() => {
    handleAddProductToCart();
  }, [mainDishQuantity]);

  useEffect(() => {
    if (productId) {
      getProductById(productId);
      getSideDishes(productId);
    }
  }, [productId]);

  useEffect(() => {
    getTotalFromCart();
  }, [mainDishQuantity, cart.additionalsDish]);

  const handleReturnRestaurant = useCallback(() => {
    if (!cart.product) {
      const newProduct = whenNotAddMainDishQuantity(product, 1);
      addProduct(newProduct);
    } else {
      addProduct(cart);
    }

    window.history.back();
  }, [cart, product]);

  return (
    <S.ProductContainer>
      {loadings.getProductById ? (
        <Loading message="Carregando informações do produto..." />
      ) : (
        <>
          <S.ItemImage src={product?.photo?.url} />
          <S.ProductHeader>
            <S.Content>
              <S.ProductTitle>{product?.title}</S.ProductTitle>
              <S.ProductPrice>{formatCurrency(product?.price)}</S.ProductPrice>
            </S.Content>

            <S.ContentRight>
              <S.ProductDetails>{product?.description}</S.ProductDetails>
            </S.ContentRight>
          </S.ProductHeader>

          <S.ObsContent>
            <S.ObsTitle>Alguma observação?</S.ObsTitle>
            <S.ObsInput
              onChange={(e) => setObservation(e.currentTarget.value)}
            />
          </S.ObsContent>
        </>
      )}

      {loadings.getSideDishes ? (
        <Loading message="Buscando acompanhamentos..." />
      ) : (
        <AccompanimentSelector
          handleAddAccompanimentToCart={handleAddAccompanimentToCart}
          sideDish={sideDishes}
        />
      )}

      {product?.id && (
        <S.AddBar>
          <S.QuantitySelector>
            <S.QuantityButton
              onClick={() => handleQuantityChange(-1)}
              disabled={mainDishQuantity <= 1}
            >
              -
            </S.QuantityButton>
            <S.QuantityAmount>{mainDishQuantity}</S.QuantityAmount>
            <S.QuantityButton onClick={() => handleQuantityChange(1)}>
              +
            </S.QuantityButton>
          </S.QuantitySelector>
          <S.AddButton onClick={handleReturnRestaurant}>
            Adicionar {formatCurrency(totalPrice)} <BiCartAdd />
          </S.AddButton>
        </S.AddBar>
      )}
    </S.ProductContainer>
  );
};

export default ProductDetail;
