import { parseResponse } from "../../utils";
import { httpService } from "../axios"

export const getOrders = async () => {
  const { data } = await httpService.get("/orders");

  const orders = parseResponse(data.orders);
  return orders;
}

export const getOrderById = async (id) => {
  const { data } = await httpService.get(`/orders/${id}`);

  const order = parseResponse(data.order);
  return order;
}