import { getTimezone, parseResponse, transformTitleInSlug } from "../../utils";
import { httpService } from "../axios"

const restaurantDto = (restaurant) => {
  return {
    ...restaurant,
    slug: transformTitleInSlug(restaurant.title),
  };
}

export const getRestaurants = async (hangar) => {
  const { data } = await httpService.get("/restaurants", {
    params: {
      hangar: hangar.id,
      tz: getTimezone(),
    },
  });

  const restaurantsParsed = parseResponse(data.restaurants);
  const restaurants = restaurantsParsed.map(restaurantDto);

  return restaurants;
}