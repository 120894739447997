import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductList from "../../../components/ProductList";
import ProductsFilters from "../../../components/ProductsFilters";
import { httpServiceMiddleware } from "../../../config/httpService";
import * as S from "./styles";
import { useProducts } from "../../../hooks/useProducts";

const Restaurant = () => {
  const navigate = useNavigate();
  const { getProducts, products, loading } = useProducts()
  const { id } = useParams()
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    getProducts(id);
  }, [id]);

  const handleFilterSelect = (category) => {
    setSelectedCategory(category);
  };

  const handleProductClick = (restaurantSlug, restaurantId, productSlug, productId) => {
    navigate(
      `/restaurante/${restaurantSlug}/${restaurantId}/${productSlug}/${productId}`
    );
  };

  const filteredProduct = (products, category) => {
    if (category) {
      return products.filter(
        (product) => product.productCategory.id === category
      );
    } else {
      return products;
    }
  };


  return (
    <>
      <S.ItemImage src="/assets/restaurant/unities/unidade.jpg" />
      {/* <SuggestionsCarousel /> */}
      <ProductsFilters
        onFilterSelect={handleFilterSelect}
        idRestaurant={id}
      />
      <ProductList
        products={filteredProduct(products, selectedCategory)}
        onProductClick={handleProductClick}
        loading={loading}
      />
    </>
  );
};

export default Restaurant;
