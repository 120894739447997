import React, { useState } from "react";
import * as S from "./styles";
import { formatCurrency } from "../../utils/currency";

const AccompanimentSelector = ({ sideDish, handleAddAccompanimentToCart }) => {
  const [selectedAccompaniments, setSelectedAccompaniments] = useState(0);

  const handleAccompanimentQuantityChange = (quantity, max, item) => {
    if (selectedAccompaniments === max && quantity > 0) return;
    setSelectedAccompaniments(selectedAccompaniments + quantity);
    handleAddAccompanimentToCart(item, selectedAccompaniments + quantity);
  }

  return !!sideDish?.length && (
    <>
      <S.Header>
        <S.Content>
          <S.Title>Selecione os acompahamentos que deseja</S.Title>
          {/* <S.SubTitle>Escolha até 3 opções ou mais</S.SubTitle> */}
        </S.Content>
      </S.Header>
      <S.AccompanimentContainer>
        {sideDish?.map((item) => (
          <S.AccompanimentItem key={item?.id}>
            <S.ContentInfos>
              <S.AccompanimentName>
                {item?.title}
              </S.AccompanimentName>
              <S.AccompanimentPrice>
                {formatCurrency(item?.price)}
              </S.AccompanimentPrice>
              {
                item?.serves && (
                  <S.AccompanimentName>
                    <i>
                      Serve até {item?.serves > 1 ? `${item?.serves} pessoas` : `${item?.serves} pessoa`}
                    </i>
                  </S.AccompanimentName>
                )
              }
            </S.ContentInfos>
            <S.QuantitySelector>
              <S.QuantityButton
                onClick={() => handleAccompanimentQuantityChange(-1, item?.max, item)}
                disabled={selectedAccompaniments === 0}
              >
                -
              </S.QuantityButton>
              <S.QuantityAmount>{selectedAccompaniments}</S.QuantityAmount>
              <S.QuantityButton
                onClick={() => handleAccompanimentQuantityChange(1, item?.max, item)}
              >
                +
              </S.QuantityButton>
            </S.QuantitySelector>
          </S.AccompanimentItem>
        ))}
      </S.AccompanimentContainer>
    </>
  );
};

export default AccompanimentSelector;
