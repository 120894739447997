// Styled components para CheckoutScreen

import styled from 'styled-components';

export const CheckoutContainer = styled.div`
  background: #f0f0f0;

  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
