import { BiSupport } from 'react-icons/bi';
import { RiChatSmile2Line } from 'react-icons/ri';
import * as S from './styles';

const supportImage = '/assets/consience/step02.svg';

const ChatContent = () => {
  return (
    <S.Container>
      <S.SupportContainer>
        <S.SupportEmailButton
          onClick={() => (window.location = 'mailto:support@epedido.com')}
        >
          {/* Replace 'email-icon.png' with the path to your email icon */}
          Abra um chamado por email
          <BiSupport />
        </S.SupportEmailButton>

        <S.SupportEmailButton
          onClick={() => (window.location = 'mailto:support@epedido.com')}
        >
          {/* Replace 'email-icon.png' with the path to your email icon */}
          Fala via WhatsApp
          <RiChatSmile2Line />
        </S.SupportEmailButton>
      </S.SupportContainer>
      <S.SupportImage src={supportImage} />
    </S.Container>
  );
};

export default ChatContent;
