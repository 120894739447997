import { useState } from 'react';
import Swal from 'sweetalert2';
import * as servicesCategories from '../../services/categories'

export const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCategories = async (restaurant) => {
    try {
      setLoading(true)
      const categories = await servicesCategories.getCategories(restaurant);
      setCategories(categories);
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: 'Erro ao buscar as categorias',
        text: 'Ocorreu um erro ao buscar as categorias, por favor tente novamente',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    } finally {
      setLoading(false);
    }
  };

  return { categories, loading, getCategories };
}