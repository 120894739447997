// styles.js for OrderTrackingScreen
import styled from "styled-components";

// CartItem.js

export const CartItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;
  padding: 0px;
  background-color: #fff; // Change as per your design
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

export const ItemDetails = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemDetailsCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  padding-left: 1em;
`;
export const ItemImage = styled.img`
  width: 80px; // Or the size you need
  height: 80px;
  margin-right: 10px;
  border-radius: 12px;
  object-fit: cover;
`;

export const ItemName = styled.span`
  font-weight: 400;
  font-size: 16px;
`;

export const ItemPrice = styled.span`
  margin-left: auto;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
`;

export const QuantityAdjuster = styled.div`
  display: flex;
  align-items: center;

  padding: 2rem;
`;

export const Quantity = styled.span`
  min-width: 20px;
  font-size: 16px;
  text-align: center;
`;

// OrderSummary.js

export const SummaryContainer = styled.div`
  width: 100%;
  padding: 2em;
  border-radius: 15px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  margin: 20px 0;
`;

export const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`;

export const SummaryLabel = styled.span`
  font-size: 16px;
  color: #333; // Or any color you prefer
`;

export const SummaryAmount = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: #333;
`;

// PickupAddres.js

export const AddressContainer = styled.div`
  width: 100%;
  padding: 2em;
  border-radius: 15px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

export const AddressLabel = styled.span`
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 0.3em;
  svg {
    font-size: 22px;
  }
  color: #333; // Or any color you prefer
`;

export const AddressValue = styled.span`
  font-weight: 500;
  font-size: 15px;
  color: #333;
`;

export const EditButton = styled.button`
  background-color: transparent;
  border: none;
  margin-left: 0.8em;
  background-color: #f3f3f3;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

// PaymentMethod.js

export const PaymentMethodContainer = styled.div`
  width: 100%;
  padding: 2em;
  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.03);
  margin: 20px 0 10px;
`;

export const PaymentMethodTitle = styled.h3`
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 400;
`;

export const PaymentOptionContainer = styled.div`
  display: flex;
  margin-top: 2em;
  align-items: center;
`;

export const PaymentOption = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const PaymentRadio = styled.input.attrs({ type: "radio" })`
  margin-right: 10px;
`;

export const PaymentLabel = styled.span`
  font-size: 16px;
`;

export const PaymentIcon = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 8px;
  margin-right: 10px;
`;

// CPFInput.js

export const CPFInputContainer = styled.div`
  width: 100%;
  padding-top: 3em;
`;

export const CPFInputLabel = styled.label`
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 0.8em;
`;

export const CPFInputField = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--gray-400, #d1d5db);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  height: 65px;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

// Add any other styles you need for your CPFInput component

// Complete Button
// styles.js

// ... other styled components

export const CompleteOrderButtonStyled = styled.button`
  width: 100%;
  border-radius: 15px;
  border: 1px solid var(--Green, #01c59c);
  background: var(--Green, #01c59c);
  backdrop-filter: blur(10px);
  color: white;
  display: flex;
  width: 100%;
  height: 70px;
  padding: 0 2em;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  font-size: 16px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  svg {
    font-size: 28px;
  }

  &:hover {
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const Button = styled.button`
  padding: 1em;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid var(--Platinum, #e0e0e0);
  color: #707070;
  background: var(--White, #fff);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  width: 100%;
  height: 70px;
  padding: 0 2em;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  svg {
    font-size: 28px;
  }

  &:hover {
    box-shadow: none;
  }
`;

export const Modal = styled.div``;

export const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  width: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 3em;
  border-radius: 21px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 2;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #333;
`;

export const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  }
`;

export const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #218838;
  }
`;

export const AdditionButton = styled.button`
  padding: 10px 15px;
  border-radius: 15px;
  background: ${({ isTrash }) =>
    isTrash ? "#ffd6d6" : "var(--Green, #01c59c)"};
  color: white;
  border: none;
  color: #6d1c00;
  text-align: center;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  transition: background-color 0.2s ease;

  svg {
    font-size: 28px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  ${({ isTrash }) =>
    isTrash &&
    `
    margin-bottom: 1em;
  `}
`;

export const TrackingContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 15em;
`;

export const InlineRow = styled.div`
  display: flex;
  margin-top: 0.8em;
  align-items: center;
  gap: 0.3em;
`;

export const AdditionalsItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const Top = styled.div`
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff; // Change as per your design
`;

export const Bottom = styled.div`
  width: 100%;
  padding-left: 115px;
  padding-bottom: 2em;
`;

export const QuantitySelector = styled.div`
  display: flex;
  align-items: center;
`;

export const QuantityButton = styled.button`
  padding: 5px 10px;
  margin: 0 10px;
  border: none;
  width: 30px;
  background-color: #00000010;
  border-radius: 12px;
  height: 30px;
  // Add more styles as needed
`;

export const QuantityAmount = styled.span`
  font-size: 1.5rem;
  min-width: 20px;
  text-align: center;
`;

export const EmptyCart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 5em;
  flex-direction: column;
  gap: 1em;
`;

export const EmptyCartText = styled.h2`
  font-size: 24px;
  color: #333;
`;

export const CreditCardList = styled.div`
  display: flex;
  gap: 1em;
  margin-top: 1em;
  margin-bottom: 1rem;
  flex-direction: column;
`;

export const CreditCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;

  padding: 1em;
  border-radius: 8px;
  background-color: #f3f3f3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 100%;

  &:hover {
    background-color: #e9e9e9;
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: #e9e9e9;
    border: 2px solid;
  `}
`;

export const CardBrand = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  font-size: 14px;
`;

export const CardNumber = styled.div`
  font-size: 16px;
`;

export const RadioWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  width: 1em;
`;
export const Wrap = styled.div`
  display: flex;
  gap: 2em;
`;
