import { RotatingLines } from "react-loader-spinner";
import * as S from "./styles";

const Loading = ({ message }) => {
  return (
    <S.Container>
      <RotatingLines
        visible={true}
        height="50"
        width="50"
        strokeColor="red"
        strokeWidth="5"
        animationDuration="1"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />

      <>{message}</>
    </S.Container>
  )
}

export default Loading
