import { ChangePassword } from "./changePassword";
import PersonalData from "./personalData";
import * as S from "./styles";

const UserSettings = ({ redirect }) => {
  return (
    <S.Body>
      <PersonalData />
      {/* <ChangePassword /> */}
    </S.Body>
  );
};

export default UserSettings;
