// src/components/BackButton/index.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const leftIcon = '/assets/account/left.svg';

const BackButtonStyled = styled.button`
  /* Add your styling here */
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #595959;
  text-decoration: none;
  font-size: 16px;
  padding: 1em;
  width: 100%;
  text-align: left;
  display: flex;
  gap: 0.5em;

  transition: all 0.3s ease;

  &:hover {
    transform: translateX(-10px);
    img {
    }
  }
`;

const LeftIcon = styled.img`
  width: 14px;
  height: 14px;
`;

const BackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // This will take the user back to the last page
  };

  return (
    <BackButtonStyled onClick={goBack}>
      <LeftIcon src={leftIcon} />
      Retornar à página anterior
    </BackButtonStyled>
  );
};

export default BackButton;
