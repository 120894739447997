import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4em 2em 3em 2em;
  margin-bottom: 5em;

  background-color: white;
  border: none;
  box-shadow: -1px 0px 40px #5f5f5f4a;
  border-bottom-left-radius: 2.2em;
  border-bottom-right-radius: 2.2em;

  height: 6em;
`;

export const Content = styled.div`
  border: none;

  border: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10em;

  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 10px;
  padding: 2em;
  gap: 2em;
  box-shadow: -1px 0px 40px #5f5f5f4a;
`;
export const Title = styled.h2`
  font-size: 18px;

  font-weight: 600;
  color: #666;
`;

export const TitleInput = styled.p`
  font-size: 2em;

  font-weight: 600;
`;
export const InputGroup = styled.div`
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    font-size: 22px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 1.4em;
  padding-left: 60px;
  border-radius: 5px;
  border: 1px solid #ddd;
  height: 50px;
  font-size: 16px;
  border-radius: 15px;
  border: 1px solid #dfdfdf;
  background: var(--White, #fff);
`;

export const SendButton = styled.button`
  display: flex;
  width: 100%;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  border: 0.925px solid #fa0c0c;
  background: var(--Green, #fa0c0c);
  box-shadow: 0px 0px 8.651px 0px rgba(0, 0, 0, 0.05);
  height: 60px;
  padding: 19px 33px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  text-align: center;
  border: none;

  &:hover {
    box-shadow: none;
    color: #fa0c0c;
    background-color: #fff;
    transition: all 0.3s ease;
    border: 0.925px solid #fa0c0c;
  }

  svg {
    font-size: 28px;
  }
`;

export const BackButton = styled.button`
  display: flex;
  width: 90%;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  border: 0.925px solid #fa0c0c;
  background: var(--Green, #fa0c0c);
  box-shadow: 0px 0px 8.651px 0px rgba(0, 0, 0, 0.05);
  height: 60px;
  padding: 19px 33px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  text-align: center;
  border: none;
  align-self: center;

  &:hover {
    box-shadow: none;
    color: #fa0c0c;
    background-color: #fff;
    transition: all 0.3s ease;
    border: 0.925px solid #fa0c0c;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

export const ErrorText = styled.p`
  font-size: 1.5em;
  color: var(--Green, #fa0c0c);
`;

export const Logo = styled.img`
  max-width: 200px;
  margin: 2em;
`;
