import React, { useEffect, useState } from "react";
import {
  PiArrowRightDuotone,
  PiDotsThreeDuotone,
  PiPasswordDuotone,
  PiUserPlusDuotone,
} from "react-icons/pi";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";

import { TOKEN_NAME } from "../../../env";
import * as S from "./styles";
import { httpService } from "../../../services/axios";
import { saveTokenOnServer } from "../../../services/notifications";

const Login = ({ redirect }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ email: "", password: "" });

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_NAME);
    if (token) {
      navigate("/minha-conta/menu");
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleLogin = async () => {
    const { email, password } = credentials;
    try {
      if (!email || !password) {
        throw new Error("Preencha todos os campos");
      }

      Swal.fire({
        title: "Aguarde...",
        text: "Estamos verificando suas credenciais.",
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      localStorage.removeItem(TOKEN_NAME);

      setLoading(true);

      const { data: response } = await httpService.post("/auth", {
        email: credentials.email,
        password: credentials.password,
      });

      Swal.close();

      localStorage.setItem(TOKEN_NAME, response.jwt);

      saveTokenOnServer();
      
      if (redirect) {
        navigate(redirect);
        return;
      }

      navigate("/minha-conta/menu");
    } catch (error) {
      Swal.fire("Oops!", "Usuário e/ou senha incorreto.", "warning");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <S.Body>
      <S.Forms>
        <S.InputGroup>
          <PiUserPlusDuotone />

          <S.Input
            name="email"
            value={credentials.email}
            onChange={handleChange}
            placeholder="E-mail"
            icon="envelope"
            onKeyDown={handleKeyDown}
          />
        </S.InputGroup>
        <S.InputGroup>
          <PiPasswordDuotone />

          <S.Input
            name="password"
            value={credentials.password}
            placeholder="********"
            onChange={handleChange}
            icon="lock"
            type="password"
            onKeyDown={handleKeyDown}
          />
        </S.InputGroup>
        <Link to="/esqueci-minha-senha">
          <S.ForgotPassword>
            <PiDotsThreeDuotone /> Esqueci minha senha
          </S.ForgotPassword>
        </Link>

        <S.SendButton onClick={handleLogin} disabled={loading}>
          Acessar minha conta
          <PiArrowRightDuotone />
        </S.SendButton>
        {/* <S.SendButton onClick={() => navigate('/register')} disabled={loading}>
          Cadastre
        </S.SendButton> */}
      </S.Forms>
    </S.Body>
  );
};

export default Login;
