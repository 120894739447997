import { useState } from 'react';
import * as servicesHangars from '../../services/hangars'

export const useHangars = () => {
  const [hangars, setHangars] = useState([]);
  const [loading, setLoading] = useState(true);

  const getHangars = async (coords) => {
    try {
      setLoading(true)
      const hangars = await servicesHangars.getHangars(coords);
      setHangars(hangars);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return { hangars, loading, getHangars };
}