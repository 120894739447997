import axios from "axios";
import Swal from "sweetalert2";

const getAlternativesGeolocations = async () => {
  const alternatives = [
    "https://ipapi.co/json/"
  ];

  const alternativesPromises = alternatives.map((url) => axios.get(url));
  const coords = await Promise.race(alternativesPromises);

  return {
    latitude: coords.data.lat || coords.data.latitude,
    longitude: coords.data.lon || coords.data.longitude,
  };
}

export async function getLocation() {
  try {
    const coords = await getAlternativesGeolocations()
    return coords;
  } catch (error) {
    console.error(error);
    Swal.fire({
      title: 'Erro ao buscar a localização',
      text: 'Ocorreu um erro ao buscar a localização, por favor tente novamente',
      icon: 'error',
      confirmButtonText: 'OK'
    })    
  }
}
