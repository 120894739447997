import React from "react";
import Highbanners from "../../components/Highbanners";
import HighlightsSection from "../../components/HighlightsSection";
import LocationSelector from "../../components/LocationSelector";
import RestaurantList from "../../components/RestaurantList";
import * as S from "./styles";

const Categorie = () => {
  return (
    <>
      <S.HeadSection>
        <LocationSelector />
      </S.HeadSection>
      <Highbanners />

      <RestaurantList />

      {/* <HighlightsSection /> */}
    </>
  );
};

export default Categorie;
