import React, { useState } from 'react';
import OrderTrackingScreen from '../../../components/OrderTrackingScreen';
import * as S from './styles';

const CheckoutScreen = () => {
  return (
    <S.CheckoutContainer>
      <OrderTrackingScreen />
    </S.CheckoutContainer>
  );
};

export default CheckoutScreen;
