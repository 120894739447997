import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GlobalStyle, Main } from './styles/global';
import { HangarProvider } from './context/HangarProvider';
import { CartProvider } from './context/CartContext';
import WebSocketProvider from './context/WebSocketProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WebSocketProvider>
    <HangarProvider>
      <CartProvider>
        <Main>
          <GlobalStyle />
          <App />
        </Main>
      </CartProvider>
    </HangarProvider>
  </WebSocketProvider>
);
