import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  PiArrowCircleRightDuotone,
  PiCrownSimpleDuotone,
  PiEnvelopeSimpleDuotone,
  PiPasswordDuotone,
  PiPhoneCallDuotone,
  PiShieldCheckDuotone,
  PiUserPlusDuotone,
} from "react-icons/pi";
import InputMask from "react-input-mask";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { TOKEN_NAME } from "../../../env";

import * as S from "./styles";
import { httpService } from "../../../services/axios";

const Register = ({ redirect }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const schema = yup.object({
    personalName: yup.string().required("Este campo é obrigatório."),
    username: yup.string().required("Este campo é obrigatório."),
    email: yup
      .string()
      .email("O campo é do tipo email.")
      .required("Este campo é obrigatório."),
    confirmEmail: yup
      .string()
      .email("O campo é do tipo email.")
      .oneOf([yup.ref("email"), null], "Os emails não coincidem")
      .required("Este campo é obrigatório."),
    password: yup
      .string()
      .required("Este campo é obrigatório.")
      .min(6, "Você precisa inserir no minimo 6 caracteres"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "As senhas não coincidem")
      .required("Você precisa confirmar a senha")
      .min(6, "Você precisa inserir no minimo 6 caracteres"),
    phone: yup.string().required("Este campo é obrigatório."),
    doc: yup
      .string()
      .required("Este campo é obrigatório.")
      .test("teste", "Documento inválido.", (value) => validateDoc(value)),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    data.role = "3";
    try {
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos cadastrando seu usuário.",
        showConfirmButton: false,
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      setLoading(true);
      await httpService.post("/users/customer", {
        email: data.confirmEmail,
        name: data.personalName,
        password: data.password,
        confirmPassword: data.confirmPassword,
        cellphone: data.phone,
        cpfCnpj: data.doc,
      });

      setLoading(false);
      await Swal.fire("Sucesso!", "Usuário cadastrado com sucesso. Enviamos um link para confirmação de conta em seu email.", "success");
      // await handleLogin(data);
    } catch (error) {
      console.log(error);
      let errorMessage = "Ocorreu um erro inesperado tente novamente!";

      if (error?.response?.data?.message === "Email already taken") {
        errorMessage = "Email já cadastrado. Faça login para continuar";
        Swal.fire("Oops!", errorMessage, "error");
        Swal.fire({
          icon: "warning",
          title: "Email já cadastrado",
          text: "Faça login para continuar",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Ir para o login",
          cancelButtonText: "Mudar email",
        }).then((response) => {
          if (response.isConfirmed) {
            window.location.reload();
          }
        });

        return;
      }

      if (error?.response?.data?.message === "CPF já cadastrado") {
        errorMessage = "Email já cadastrado. Faça login para continuar";
        Swal.fire("Oops!", errorMessage, "error");
        Swal.fire({
          icon: "warning",
          title: "CPF já cadastrado",
          text: "Faça login para continuar",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Ir para o login",
        }).then((response) => {
          if (response.isConfirmed) {
            window.location.reload();
          }
        });

        return;
      }

      setLoading(false);
      Swal.fire("Oops!", errorMessage, "error");
    }
  };

  const handleLogin = async ({ email, password }) => {
    try {
      if (!email || !password) {
        throw new Error("Preencha todos os campos");
      }

      setLoading(true);
      const { data: response } = await httpService.post("/auth", {
        email: email,
        password: password,
      });

      localStorage.setItem(TOKEN_NAME, response.jwt);

      const toRedirect = redirect || params.get("redirect");

      if (toRedirect) {
        navigate(`${toRedirect}`);
        return;
      }

      navigate("/minha-conta/menu");
    } catch (error) {
      Swal.fire("Oops!", "Usuário e/ou senha incorreto.", "warning");
    } finally {
      setLoading(false);
    }
  };

  function validateDoc(cpf) {
    cpf = cpf.replace(/[^\d]/g, "");

    if (cpf.length !== 11) {
      return false;
    }

    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }

    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let remainder = 11 - (sum % 11);
    let verificationDigit1 =
      remainder === 10 || remainder === 11 ? 0 : remainder;

    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = 11 - (sum % 11);
    let verificationDigit2 =
      remainder === 10 || remainder === 11 ? 0 : remainder;

    return (
      verificationDigit1 === parseInt(cpf.charAt(9)) &&
      verificationDigit2 === parseInt(cpf.charAt(10))
    );
  }

  return (
    <S.Body>
      <S.Forms onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <PiUserPlusDuotone />
          <S.Input
            {...register("personalName")}
            id="personal_name"
            name="personalName"
            className="input"
            placeholder={"Nome"}
            icon="envelope"
          />
          {errors?.personalName && (
            <span className="error">{errors.personalName.message}</span>
          )}
        </S.InputGroup>

        <S.InputGroup>
          <PiPhoneCallDuotone />

          <InputMask
            mask="(99)9 999-99999"
            maskChar={null}
            className="input"
            placeholder={"Telefone"}
            style={{ fontSize: "16px" }}
            {...register("phone")}
          >
            {(inputProps) => <S.Input {...inputProps} />}
          </InputMask>
          {errors?.phone && (
            <span className="error">{errors.phone.message}</span>
          )}
        </S.InputGroup>

        {/* 
        <S.Input
          {...register('username')}
          id='username'
          className='input'
          placeholder={'Usuário'}
          icon='envelope'
        />
        {errors?.username && (
          <span className='error'>{errors.username.message}</span>
        )} */}
        <S.InputGroup>
          <PiCrownSimpleDuotone />

          <InputMask
            mask="999.999.999-99"
            maskChar={null}
            className="input"
            placeholder={"CPF"}
            {...register("doc")}
            style={{ fontSize: "16px" }}
          >
            {(inputProps) => <S.Input {...inputProps} />}
          </InputMask>
          {errors?.doc && <span className="error">{errors.doc.message}</span>}
        </S.InputGroup>

        <S.InputGroup>
          <PiEnvelopeSimpleDuotone />

          <S.Input
            {...register("email")}
            placeholder={"E-mail"}
            icon="envelope"
            className="input"
            onBlur={(e) => {
              setValue("username", e.target.value);
            }}
          />
          {errors?.email && (
            <span className="error">{errors.email.message}</span>
          )}
        </S.InputGroup>

        <S.InputGroup>
          <PiEnvelopeSimpleDuotone />

          <S.Input
            {...register("confirmEmail")}
            placeholder={"Confirmar E-mail"}
            icon="envelope"
            className="input"
            onBlur={(e) => {
              setValue("username", e.target.value);
            }}
          />
          {errors?.confirmEmail && (
            <span className="error">{errors.confirmEmail.message}</span>
          )}
        </S.InputGroup>

        <S.InputGroup>
          <PiPasswordDuotone />
          <S.Input
            {...register("password")}
            placeholder={"Senha"}
            icon="lock"
            className="input"
            type="password"
          />
          {errors?.password && (
            <span className="error">{errors.password.message}</span>
          )}
        </S.InputGroup>

        <S.InputGroup>
          <PiShieldCheckDuotone />
          <S.Input
            {...register("confirmPassword")}
            placeholder={"Confirmar senha"}
            icon="lock"
            className="input"
            type="password"
          />
          {errors?.confirmPassword && (
            <span className="error">{errors.confirmPassword.message}</span>
          )}
        </S.InputGroup>

        <S.SendButton type="submit" disabled={loading}>
          Cadastrar
          <PiArrowCircleRightDuotone />
        </S.SendButton>
      </S.Forms>
    </S.Body>
  );
};

export default Register;
