import { parseResponse, transformTitleInSlug } from "../../utils";
import { httpService } from "../axios"

const productDto = (product) => {
  return {
    ...product,
    slug: transformTitleInSlug(product.title),
    ...(product.restaurant && { restaurant: { ...product.restaurant, slug: transformTitleInSlug(product.restaurant.title) } })
  };
}

export const getProducts = async (restaurant) => {
  const { data } = await httpService.get("/products", {
    params: {
      restaurant
    },
  });

  const productsParsed = parseResponse(data.products);

  const products = productsParsed.map(productDto);

  return products;
}

export const getSideDishes = async (product) => {
  const { data } = await httpService.get("/products/side-dishes", {
    params: {
      product
    },
  });

  const sideDishes = parseResponse(data.sideDishes);

  return sideDishes;
}

export const getProductById = async (id) => {
  const { data } = await httpService.get(`/products/${id}`);

  const productParsed = parseResponse(data.product);

  const product = productDto(productParsed);

  return product;
}