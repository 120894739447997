import axios from "axios";
import { httpService } from "../axios";
import { parseResponse } from "../../utils";

export const createCard = async (card, type) => {
  const { data } = await axios.post(
    `https://api.pagar.me/core/v5/tokens?appId=${process.env.REACT_APP_PAGARME_PUBLIC_KEY}`,
    {
      type: "card",
      card,
    }
  );

  const { id } = data;

  await saveIdCard(id, type);

  return data;
};

export const saveIdCard = async (cardToken, cardType) => {
  const { data } = await httpService.post("/pagarme/cards", {
    cardToken,
    cardType,
  });

  return data;
};

export const getCards = async () => {
  const { data } = await httpService.get("/pagarme/cards");

  return data.cards;
};

export const createOrder = async (items, method, cardId) => {
  const restaurantStringified = localStorage.getItem("restaurant");
  const restaurant = JSON.parse(restaurantStringified);

  const { data } = await httpService.post("/orders", {
    restaurant: restaurant.id,
    method,
    items,
    ...(method === "credit_card" && { cardId }),
  });

  return parseResponse(data.order);
};
