import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  PiArrowCircleRightDuotone,
  PiCrownSimpleDuotone,
  PiEnvelopeSimpleDuotone,
  PiPhoneCallDuotone,
  PiUserPlusDuotone,
} from "react-icons/pi";
import InputMask from "react-input-mask";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import * as S from "./styles";
import { TOKEN_NAME } from "../../../../env";
import { httpService } from "../../../../services/axios";
import useMe from "../../../../hooks/useMe";

const PersonalData = ({ redirect }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const me = useMe();
  const schema = yup.object({
    personalName: yup.string().required("Este campo é obrigatório."),
    phone: yup.string().required("Este campo é obrigatório."),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...me,
    },
  });

  useEffect(() => {
    if (me?.id) {
      setValue("personalName", me.name);
      setValue("phone", removeMask(me.cellphone));
      setValue("doc", me.cpfCnpj);
      setValue("email", me.username);
    }
  }, [me]);

  function removeMask(numero) {
    return numero?.replace(/\D/g, "");
  }

  const onSubmit = async (data) => {
    try {
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos atualizando seu usuário.",
        showConfirmButton: false,
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      setLoading(true);
      await httpService.put("/users/customer", {
        name: data.personalName,
        cellphone: data.phone,
      });

      Swal.fire("Sucesso!", "Dados atualizados com sucesso", "success");
      setLoading(false);
    } catch (error) {
      console.log(error);
      let errorMessage = "Ocorreu um erro inesperado tente novamente!";

      Swal.fire("Oops!", errorMessage, "error");
      setLoading(false);
    }
  };

  return (
    <S.Body>
      <S.Title>Alterar meus dados</S.Title>{" "}
      <S.Forms onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <PiUserPlusDuotone />
          <S.Input
            {...register("personalName")}
            id="personal_name"
            name="personalName"
            className="input"
            placeholder={"Nome"}
            icon="envelope"
          />
          {errors?.personalName && (
            <span className="error">{errors.personalName.message}</span>
          )}
        </S.InputGroup>

        <S.InputGroup>
          <PiPhoneCallDuotone />

          <InputMask
            mask="(99)9999-99999"
            maskChar={null}
            className="input"
            placeholder={"Telefone"}
            style={{ fontSize: "16px" }}
            {...register("phone")}
          >
            {(inputProps) => <S.Input {...inputProps} />}
          </InputMask>
          {errors?.phone && (
            <span className="error">{errors.phone.message}</span>
          )}
        </S.InputGroup>

        <S.InputGroup>
          <PiCrownSimpleDuotone />

          <InputMask
            mask="999.999.999-99"
            maskChar={null}
            className="input"
            placeholder={"CPF"}
            {...register("doc")}
            style={{ fontSize: "16px" }}
            disabled={true}
          >
            {(inputProps) => <S.Input disabled={true} {...inputProps} />}
          </InputMask>
        </S.InputGroup>

        <S.InputGroup>
          <PiEnvelopeSimpleDuotone />

          <S.Input
            {...register("email")}
            placeholder={"E-mail"}
            icon="envelope"
            className="input"
            onBlur={(e) => {
              setValue("username", e.target.value);
            }}
            disabled={true}
          />
          {errors?.email && (
            <span className="error">{errors.email.message}</span>
          )}
        </S.InputGroup>

        <S.SendButton type="submit" disabled={loading}>
          Atualizar dados
          <PiArrowCircleRightDuotone />
        </S.SendButton>
      </S.Forms>
    </S.Body>
  );
};

export default PersonalData;
