import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";

const steps = [
  {
    image: "/assets/steps/imageStep1.jpeg",
    title: "Bem-vindo à nova maneira de pedir sua refeição, café ou chocolate!",
    logo: "/assets/header/logo.svg",
  },

  {
    image: "/assets/steps/imageStep2.jpeg",
    title:
      "Você, sua família e seus amigos podem procurar sua mesa com calma ou admirar aquela vitrine que você gosta, enquanto seu pedido é feito. Explore todas as possibilidades!",
    logo: "/assets/header/logo.svg",
  },
];

const ConscientizationSection = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [slideIn, setSlideIn] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setSlideIn(false);
    const timer = setTimeout(() => {
      setSlideIn(true);
    }, 50);
    return () => clearTimeout(timer);
  }, [currentStep]);

  const nextStep = () => {
    if (currentStep === steps.length - 1) {
      navigate("/selecionar-shopping");
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const skipStep = () => {
    navigate("/selecionar-shopping");
  };
  const { image, title, logo } = steps[currentStep];

  return (
    <S.SectionContainer>
      <S.StepContainer slideIn={slideIn}>
        <S.Logo src={logo} alt="Epedido" />
        <S.StepImage src={image} alt="Step Illustration" />
        <S.StepTitle>{title}</S.StepTitle>
      </S.StepContainer>
      <S.SkipButton onClick={skipStep}>
        Eu já conheço, pular passo a passo
      </S.SkipButton>
      <S.NavigationButton onClick={nextStep}>
        Próximo passo <BsArrowRight />
      </S.NavigationButton>
      {/* <S.PaginationDots currentStep={currentStep}>
        {steps.map((_, index) => (
          <S.Dot key={index} active={index === currentStep} />
        ))}
      </S.PaginationDots> */}
    </S.SectionContainer>
  );
};

export default ConscientizationSection;
