import { httpService } from "../axios";

export const saveTokenLocally = async (token) => {
  localStorage.setItem('firebaseToken', token);
}

export const getTokenLocally = async () => {
  return localStorage.getItem('firebaseToken');
}

export const saveTokenOnServer = async () => {
  const notificationId = await getTokenLocally();
  if(!notificationId) return;
  
  try {
    const { data, error } = await httpService.patch('/users/notification-id', {
      notificationId
    });

    if (error) {
      throw {
        message: error.message,
        status: error.status,
      };
    }

    return data;
  } catch (error) {
    throw {
      message: error.message,
      status: error.status,
    };
  }
}