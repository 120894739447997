import { useState } from 'react';
import Swal from 'sweetalert2';
import * as servicesRestaurants from '../../services/restaurants'

export const useRestaurants = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);

  const getRestaurants = async (hangar) => {
    try {
      setLoading(true)
      const restaurants = await servicesRestaurants.getRestaurants(hangar);
      setRestaurants(restaurants);
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: 'Erro ao buscar restaurantes',
        text: 'Ocorreu um erro ao buscar os restaurantes, por favor tente novamente',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    } finally {
      setLoading(false);
    }
  };

  return { restaurants, loading, getRestaurants };
}