import { useState } from 'react';
import Swal from 'sweetalert2';
import * as servicesOrders from '../../services/orders'

export const useOrders = () => {
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  const getOrderById = async (id) => {
    try {
      setLoading(true)
      const order = await servicesOrders.getOrderById(id);
      setOrder(order);
    } catch (error) {
      Swal.fire({
        title: 'Erro ao buscar o pedido',
        text: 'Ocorreu um erro ao buscar o pedido, tente novamente mais tarde.',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    } finally {
      setLoading(false);
    }
  };

  const getOrders = async () => {
    try {
      setLoading(true)
      const orders = await servicesOrders.getOrders();
      setOrders(orders);
    } catch (error) {
      Swal.fire({
        title: 'Erro ao buscar a lista de pedidos',
        text: 'Ocorreu um erro ao buscar a lista de pedidos, tente novamente mais tarde.',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    } finally {
      setLoading(false);
    }
  };

  return { orders, order, loading, getOrders, getOrderById };
}