// OrderSummary.js
import React from 'react';
import * as S from './styles'; // This assumes your styles.js is in the same directory
import { formatCurrency } from '../../utils/currency';

export const OrderSummary = ({ subtotal, total }) => {
  return (
    <S.SummaryContainer>
      {/* <S.SummaryRow>
        <S.SummaryLabel>Subtotal</S.SummaryLabel>
        <S.SummaryAmount>{subtotal}</S.SummaryAmount>
      </S.SummaryRow> */}
      <S.SummaryRow>
        <S.SummaryLabel>Total</S.SummaryLabel>
        <S.SummaryAmount>{formatCurrency(total)}</S.SummaryAmount>
      </S.SummaryRow>
    </S.SummaryContainer>
  );
};

export default OrderSummary;
