import React from "react";
import * as S from "./styles";
import Loading from "../Loading";
import { formatCurrency } from "../../utils/currency";

const ProductList = ({ products, loading, onProductClick, showPrice = true }) => {
  const navigateToProduct = (product) => {
    onProductClick(
      product?.restaurant.slug,
      product?.restaurant.id,
      product?.slug,
      product?.id
    );
  };

  const truncatedText = (text) => {
    if(!text) return;
    const slicedText = text?.slice(0, 70) + "...";
    return slicedText;
  };

  if (loading) {
    return (
      <S.EmptyList>
        <Loading message="Carregando produtos..." />
      </S.EmptyList>
    );
  }

  if (!products?.length) {
    return <S.EmptyList>Nenhum produto encontrado</S.EmptyList>;
  }

  return (
    <S.ListContainer>
      {products?.map((product) => (
        <S.ProductCard
          key={product?.id}
          onClick={() => navigateToProduct(product)}
        >
          <S.ProductImage src={product?.photo?.url} alt={product?.title} />
          <S.ProductInfo>
            <S.ProductName>{product?.title}</S.ProductName>
            <S.ProductDescription>
              {truncatedText(product?.description)}
            </S.ProductDescription>
            {
              showPrice && (
                <S.ProductPrice>{formatCurrency(product?.price)}</S.ProductPrice>
              )
            }
          </S.ProductInfo>
        </S.ProductCard>
      ))}
    </S.ListContainer>
  );
};

export default ProductList;
