import { createContext, useContext, useState } from "react";
import { HANGAR_STORAGE_KEY } from "../env";

export const HangarContext = createContext();

const setHangarLocalStorage = (hangar) => {
  localStorage.setItem(HANGAR_STORAGE_KEY, JSON.stringify(hangar));
};

const getHangarLocalStorage = () => {
  return JSON.parse(localStorage.getItem(HANGAR_STORAGE_KEY));
}

export const HangarProvider = ({ children }) => {
  const [hangar, setHangar] = useState(getHangarLocalStorage() || {});

  const selectHangar = (currentHangar) => {
    setHangar(currentHangar);
    setHangarLocalStorage(currentHangar);
  };

  return (
    <HangarContext.Provider value={{ hangar, selectHangar }}>
      {children}
    </HangarContext.Provider>
  );
}

export const useHangarContext = () => {
  const context = useContext(HangarContext);
  if (!context) {
    throw new Error("useHangarContext must be used within a HangarProvider");
  }
  return context;
}