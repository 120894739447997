// PaymentMethod.js
import React from 'react';
import * as S from './styles'; // Assuming styles.js is in the same directory

export const PaymentMethod = ({ selectedMethod, setPaymentMethod }) => {
  return (
    <S.PaymentMethodContainer>
      <S.PaymentMethodTitle>Formas de pagamento</S.PaymentMethodTitle>
      {/* Example payment methods - you would generate these based on your available payment methods */}
      <S.PaymentOptionContainer>
        <S.PaymentOption>
          <S.PaymentRadio
            checked={selectedMethod === 'credit_card'}
            onChange={() => setPaymentMethod('credit_card')}
            name='paymentMethod'
            id='paymentMethodCard'
          />
          <S.PaymentIcon src='assets/checkout/card.svg' alt='Credit Card' />
          <S.PaymentLabel htmlFor='paymentMethodCard'>
            Cartão de Crédito
          </S.PaymentLabel>
        </S.PaymentOption>
      </S.PaymentOptionContainer>
      <S.PaymentOptionContainer>
        <S.PaymentOption>
          <S.PaymentRadio
            checked={selectedMethod === 'pix'}
            onChange={() => setPaymentMethod('pix')}
            name='paymentMethod'
            id='paymentMethodPix'
          />
          <S.PaymentIcon src='assets/checkout/pix.svg' alt='PIX' />
          <S.PaymentLabel htmlFor='paymentMethodPix'>PIX</S.PaymentLabel>
        </S.PaymentOption>
      </S.PaymentOptionContainer>
      {/* Add additional payment methods as needed */}
    </S.PaymentMethodContainer>
  );
};

export default PaymentMethod;
