// AccountToggle.styles.js
import styled, { css } from "styled-components";

export const Title = styled.h2`
  // ... existing styles for Title
`;

export const ToggleContainer = styled.div`
  text-align: center;
  display: flex;
  border-radius: 17px;
  border: none;
  align-items: center;
  justify-content: center;
  gap: 4em;
  margin-bottom: 0;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
`;

export const ToggleButton = styled.button`
  padding: 1em 2em;
  color: #333;
  background-color: ${(props) =>
    props.active ? "rgb(255, 255, 255)" : "rgba(200, 200, 200, 0.8)"};
  cursor: pointer;
  border: none;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  margin-bottom: 1em;
  transition: all 0.3s ease;

  font-size: 17px;
  font-weight: 500;
  gap: 0.8em;

  svg {
    font-size: 1.2em;
  }
`;

export const ImageBanner = styled.div`
  width: 100%;
  height: 250px; // Set a fixed height or make it responsive
  object-fit: cover;
  border-radius: 0 0 32px 32px;
  background-image: url("/assets/account/register.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
`;

export const Container = styled.div`
  position: relative;
  margin-top: -2px;
  margin: auto;
`;
