// CartItem.js
import React, { useCallback } from "react";
import * as S from "./styles";
import { formatCurrency } from "../../utils/currency";
import { BiTrash } from "react-icons/bi";
import Swal from "sweetalert2";

export const CartItem = ({
  item,
  updateProductQuantity,
  removeProduct,
  index,
}) => {
  console.log("item", index);
  const sumTotal = useCallback(() => {
    let total = 0;
    total += item.productInfo.price * item.quantity;

    if (item.additionalsDish?.length) {
      item?.additionalsDish?.map((accompaniment) => {
        total +=
          accompaniment.info.price * accompaniment.quantity * item.quantity;
      });
    }

    if (!item.additionalsDish?.length) {
      total = item.productInfo.price * item.quantity;
    }

    return total || 0;
  }, [item]);

  const handleRemove = useCallback(async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Deseja remover o item do carrinho?",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      icon: "warning",
    });

    if (isConfirmed) {
      removeProduct(index);
    }
  }, [item, removeProduct]);
  return (
    <S.CartItemContainer>
      <S.Top>
        <S.ItemDetails>
          <S.ItemImage src={item?.productInfo?.photo?.url} alt={item.name} />
        </S.ItemDetails>
        <S.ItemDetailsCol>
          <S.ItemName>{item?.productInfo?.title}</S.ItemName>
          <S.ItemPrice>{formatCurrency(sumTotal())}</S.ItemPrice>
        </S.ItemDetailsCol>
        <S.QuantityAdjuster>
          <S.QuantitySelector>
            {item.quantity === 1 ? (
              <S.QuantityButton onClick={handleRemove}>
                <BiTrash />
              </S.QuantityButton>
            ) : (
              <S.QuantityButton
                onClick={() =>
                  updateProductQuantity(index, item.quantity - 1)
                }
                disabled={item.quantity <= 1}
              >
                -
              </S.QuantityButton>
            )}
            <S.QuantityAmount>{item.quantity}</S.QuantityAmount>
            <S.QuantityButton
              onClick={() =>
                updateProductQuantity(index, item.quantity + 1)
              }
            >
              +
            </S.QuantityButton>
          </S.QuantitySelector>
        </S.QuantityAdjuster>
      </S.Top>
      <S.Bottom>
        <S.AdditionalsItems>
          {item.additionalsDish.map((accompaniment, index) => (
            <>
              {accompaniment.quantity}x {accompaniment.info.title}
            </>
          ))}
        </S.AdditionalsItems>
      </S.Bottom>
    </S.CartItemContainer>
  );
};

export default CartItem;
