import { useState, useEffect } from 'react';
import { TOKEN_NAME } from '../../env';

function useLoggedIn() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_NAME);

    if (token) {
      setIsLoggedIn(true);
    }
  }, []);
  

  return { isLoggedIn };
}

export default useLoggedIn;
