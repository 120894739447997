import styled from 'styled-components';
const envelopeIcon = '/icons/crm.svg';
const lockIcon = '/icons/lock.svg';
const userIcon = '/icons/user.svg';

const icons = {
  envelope: envelopeIcon,
  user: userIcon,
  lock: lockIcon,
};

export const Container = styled.div``;
