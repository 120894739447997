// styles.js
import styled from 'styled-components';

export const ScreenContainer = styled.div`
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 9em 2em 2em;
  background-color: ${(props) => (props.isReady ? '#000' : '#FA0C0C')};
`;


export const OrderCode = styled.div`
  color: white;
  width: 100%;
  text-align: center;
  font-size: 15px;
  letter-spacing: 2px;
  margin: 0px 0;
  border-radius: 10px;
  padding: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 1em;
  cursor: pointer;
`;

export const Title = styled.h1`
  font-size: 32px;
  padding: 0 1em;
  margin-bottom: 1em;
  line-height: 32px;
  font-weight: 500;
  text-align: center;
`;

export const Subtitle = styled.p`
  font-size: 18px;
  text-align: center;
`;

export const Button = styled.button`
  background-color: #fff;
  color: #000;
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 1em;
  cursor: pointer;
  border: none;
  margin-bottom: 1em;
`