import { parseResponse } from "../../utils";
import { httpService } from "../axios"

export const getCategories = async (restaurant) => {
  const { data } = await httpService.get("/products/categories", {
    params: {
      restaurant
    },
  });

  const categories = parseResponse(data.categories);

  return categories;
}