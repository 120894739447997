import React, { useCallback, useEffect, useState } from "react";
import {
  BiCategoryAlt,
} from "react-icons/bi";
import * as S from "./styles";
import { useCategories } from "../../hooks/useCategories";
import Loading from "../Loading";

const ProductsFilters = ({ onFilterSelect, idRestaurant }) => {
  const [selectedCategory, setSelectedCategory] = useState("");

  const { categories, getCategories, loading } = useCategories();

  const handleFilter = useCallback((id, name, active) => {
    onFilterSelect(!active ? id : '');
    setSelectedCategory(!active ? name : '');
  }, [onFilterSelect])

  useEffect(() => {
    getCategories(idRestaurant);
  }, [idRestaurant]);

  if(loading) {
    return <Loading message='Buscando categorias...' />
  }

  return !!categories?.length && (
    <>
      <S.Title>
        O que você está procurando?
        <BiCategoryAlt />
      </S.Title>
      <S.FilterContainer>
        {categories?.map((category) => (
          <S.FilterTab
            key={category.name}
            selected={selectedCategory === category?.title}
            onClick={() => {
              handleFilter(category?.id, category?.title, selectedCategory === category?.title);
            }}
          >
            {category?.title}
          </S.FilterTab>
        ))}
      </S.FilterContainer>
    </>
  );
};

export default ProductsFilters;
