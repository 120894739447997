import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom'
import ConscientizationSection from '../../components/ConscientizationSection';
import Header from '../../components/Header';
import { saveTokenLocally } from '../../services/notifications';

const Discover = () => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const token = searchParams.get('token')
    alert(token)
    if(token) {
      saveTokenLocally(token);
    }
  }, [searchParams]);

  return (
    <>
      <Header />
      <ConscientizationSection />
    </>
  );
};

export default Discover;
