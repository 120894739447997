import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
// import mocked from './mocks.json'
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const cart = retrieveFromStorage();
    if (cart) {
      setCart(cart);
    }
  }, []);

  useEffect(() => {
    addToStorage(cart);
  }, [cart]);

  const addToStorage = (cart) => {
    localStorage.setItem("cart", JSON.stringify(cart));
  };

  const retrieveFromStorage = () => {
    const cart = localStorage.getItem("cart");
    return cart ? JSON.parse(cart) : [];
  };

  const addProduct = (product) => {
    const newCart = [...cart, product];

    setCart(newCart);
  };

  const removeProduct = (index) => {
    if (index < 0 || index >= cart.length) {
      return;
    }

    const updatedCart = [...cart.slice(0, index), ...cart.slice(index + 1)];
    setCart(updatedCart);
  };

  const clearCart = () => {
    setCart([]);
  };

  const updateProductQuantity = useCallback(
    (productIndex, quantity) => {
      console.log("productIndex", productIndex);
      const updatedCart = cart.map((item, index) => {
        console.log("index", index);
        if (index === productIndex) {
          return { ...item, quantity };
        }
        return item;
      });
      setCart(updatedCart);
    },
    [cart]
  );

  const getTotalPrice = () => {
    const total = cart.reduce(
      (acc, product) => acc + product.productInfo.price * product.quantity,
      0
    );

    const totalAdditionals = cart.reduce((acc, product) => {
      const total =
        product?.additionalsDish?.reduce(
          (acc, additionalProduct) =>
            acc +
            additionalProduct?.info?.price *
              additionalProduct.quantity *
              product.quantity,
          0
        ) || 0;
      return acc + total;
    }, 0);

    return total + totalAdditionals;
  };

  const getTotalQuantity = () => {
    const total = cart.reduce((acc, product) => acc + product.quantity, 0);
    return total;
  };

  const getTotalItems = () => {
    const total = cart.reduce((acc, product) => acc + product.quantity, 0);
    return total;
  };

  const getTotalItemsByProductId = (productId) => {
    const product = cart.find((item) => item.product === productId);
    return product ? product.quantity : 0;
  };

  const addAdditionalsProductItemInProduct = (product, additionalProduct) => {
    const updatedProduct = {
      ...product,
      additionalsDish: [...product.additionalsDish, additionalProduct],
    };
    return updatedProduct;
  };

  const removeAdditionalsProductItemInProduct = (
    product,
    additionalProduct
  ) => {
    const updatedProduct = {
      ...product,
      additionalsDish: product.additionalsDish.filter(
        (item) => item.id !== additionalProduct.id
      ),
    };
    return updatedProduct;
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addProduct,
        removeProduct,
        clearCart,
        updateProductQuantity,
        getTotalPrice,
        getTotalQuantity,
        getTotalItems,
        getTotalItemsByProductId,
        addAdditionalsProductItemInProduct,
        removeAdditionalsProductItemInProduct,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("usePedidoContext must be used within a PedidoProvider");
  }
  return context;
};
