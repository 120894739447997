import React from 'react';
import * as S from './styles';

const dishes = [
  {
    imageUrl: '/assets/banner/banner-03.jpg',
  },
  {
    imageUrl: '/assets/banner/banner-02.jpg',
  },

  {
    imageUrl: '/assets/banner/banner-01.jpg',
  },
];

const Highbanners = () => (
  <S.SectionContainerComplete>
    <S.SliderContainer>
      {dishes.map((dish, index) => (
        <S.DishCard key={index} backgroundImage={dish.imageUrl}>
          {/* <S.DishInfo>
            <S.DishName>{dish.name}</S.DishName>
            <S.DishPrice>{dish.price}</S.DishPrice>
          </S.DishInfo> */}
        </S.DishCard>
      ))}
    </S.SliderContainer>
  </S.SectionContainerComplete>
);

export default Highbanners;
