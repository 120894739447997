import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as servicesUser from "../../services/user";

function useMe() {
  const [me, setMe] = useState({});
  const navigate = useNavigate();

  const handleGetMeInfo = async () => {
    const data = await servicesUser.getMe();
    setMe(data);
  };

  useEffect(() => {
    handleGetMeInfo();
  }, [navigate]);

  return me;
}

export default useMe;
