import { createContext, useCallback, useEffect } from "react";
import { Manager } from "socket.io-client";
import WebSocketEventManager from "../../utils/eventManager";

const manager = new Manager(process.env.REACT_APP_WEBSOCKET_URL);

export const WebSocketContext = createContext();

const WebSocketProvider = ({ children }) => {
  const socket = manager.socket("/");
  const eventManager = new WebSocketEventManager(socket);

  const addEventListener = useCallback((eventName, callback) => {
    eventManager.addEventListener(eventName, callback);
  }, [eventManager]);

  const removeEventListener = useCallback((eventName, callback) => {
    eventManager.removeEventListener(eventName, callback);
  }, [eventManager]);

  const emitEvent = useCallback((event, data) => {
    socket.emit(event, data);
  }, [socket])

  const joinRoom = useCallback((userId) => {
    socket.emit("joinRoom", userId);
  }, [socket]);

  const leaveRoom = useCallback((userId) => {
    socket.emit("leaveRoom", userId);
  }, [socket]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected");
    });
    socket.on("disconnect", () => {
      console.log("disconnected");
      leaveRoom();
    });
  }, [])

  return (
    <WebSocketContext.Provider value={{
      emitEvent,
      addEventListener,
      removeEventListener,
      joinRoom,
      leaveRoom
    }}>
      {children}
    </WebSocketContext.Provider>
  );
}

export default WebSocketProvider