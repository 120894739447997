import styled from 'styled-components';

export const SectionContainerComplete = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2em 0 2em 0em;
  overflow-x: hidden;
`;
export const SliderContainer = styled.div`
  overflow-x: auto;
  display: flex;
  gap: 10px;
  padding-left: 2em;
  padding-right: 8em;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DishCard = styled.div`
  flex: 0 0 auto;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  width: 380px; // Adjust the width as needed
  height: 200px; // Adjust the height as needed
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
`;

export const DishInfo = styled.div`
  background: rgb(54, 54, 54);
  background: linear-gradient(
    0deg,
    rgba(54, 54, 54, 0) 0%,
    rgba(48, 48, 48, 1) 100%
  );
  padding: 3em;
  width: 100%;
`;

export const DishName = styled.h3`
  margin: 0;
  color: #fff;
  font-size: 4rem;
`;

export const DishPrice = styled.p`
  margin: 0;
  color: #fff;
  margin-top: 0.2em;
  font-size: 3rem;
`;

export const Title = styled.h2`
  font-size: 1.6rem;
  color: #666;
  margin: 0;
  margin-bottom: 1em;
  padding-left: 2em;
`;
