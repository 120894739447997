import styled from 'styled-components'

export const Container = styled.div`
  padding: 3rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`