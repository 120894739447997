// styles.js
import styled from 'styled-components';

export const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5em;
  width: 100%;
`;

export const Illustration = styled.img`
  max-width: 80%;
  height: auto;
  min-height: 340px;
  margin: 20px 0;
`;

export const Title = styled.h1`
  font-size: 15px;
  font-size: 21px;
  font-weight: 500;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  padding: 0 3em;
  text-align: center;
  margin: 10px 0;
`;

export const StarsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px 0;
`;

export const RateButton = styled.button`
  background-color: orange; // Assuming the button is orange
  color: white;
  border: none;
  border-radius: 15px;
  padding: 1em 2em;
  font-size: 1.5em;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Star = styled.span`
  cursor: pointer;
  color: #ffcc00; // Gold color for stars
  margin: 0 5px;
  font-size: 4em; // Adjust the size as needed

  &:hover {
    transform: scale(1.1); // Optional scaling effect on hover
  }
`;
